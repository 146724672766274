import React, { useEffect, useState } from 'react';
import './FundedLoans.scss';
import { clearReport, FundedReportType, getFunded } from '../../app/features/reports/reportsSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Loader from '../../components/Loader/Loader';
import DatePickerInput from '../../components/DatePickerInput/DatePickerInput';
import Modal from '../../components/Modal/Modal';
import FakeDropdown from '../../components/FakeDropdown/FakeDropdown';
import { format } from 'date-fns';
import { RootState } from '../../app/store';
import { formatMoney } from '../../utils/formatMoney';
import Title from '../../components/Title/Title';
import CsvDownload from 'react-json-to-csv';

type DateType = {
  rangeName: string,
  from: Date,
  to: Date
}

const FundedLoans = () => {
  const dispatch = useAppDispatch();
  const {funded, isLoading} = useAppSelector((state: RootState) => state.reports);

  const today = new Date();

  const [ isOpen, setIsOpen ] = useState(false);
  const [ date, selectedDate ] = useState<DateType>({
    rangeName: 'Today',
    from: today,
    to: today,
  });

  useEffect(() => {
    dispatch(getFunded({
      startDate: format(date.from, 'y/MM/dd'),
      endDate: format(date.to, 'y/MM/dd'),
    }));
    return () => {
      dispatch(clearReport());
    };
  }, [ date ]);

  let name = 'Today';

  if (date && date.rangeName) {
    name = date.rangeName;
  }

  return (
      <div className="funded">
        <div className="funded__header">
          <Title text={'Choose Wisely funded loans.'} />
          <FakeDropdown label={'Date'} onClick={() => setIsOpen(!isOpen)}>
            {name}
          </FakeDropdown>
        </div>
        <table className="funded__table">
          <thead>
          <tr>
            <th>App date</th>
            <th>Funded date</th>
            <th>Funded amount</th>
            <th>APR</th>
            <th>Reference ID</th>
          </tr>
          </thead>
          <tbody>
          {
            isLoading ? <Loader /> :
                funded.length > 1 && funded.map((elem: FundedReportType) => {
                  return (
                      <tr key={elem.reference}>
                        <td>{format(new Date(elem.appDate), 'dd/MM/y')}</td>
                        <td>{format(new Date(elem.fundedDate), 'dd/MM/y')}</td>
                        <td>{formatMoney(elem.loanAmount)}</td>
                        <td>{elem.apr}%</td>
                        <td>{elem.reference}</td>
                      </tr>
                  );
                })}
          </tbody>
        </table>
        <div className="funded__btn-block">
          <CsvDownload className="customers-table__btn" filename={`FundedLoans.csv`} data={funded}>Download csv</CsvDownload>
        </div>
        <Modal show={isOpen} onClose={() => setIsOpen(false)}>
          <DatePickerInput onClose={() => setIsOpen(false)} date={date} handleSelectedDates={selectedDate} />
        </Modal>
      </div>
  );
};

export default FundedLoans;
