import React from 'react';
import './Button.scss';
import cx from 'classnames';

type ButtonPropsType = {
  className?: string
  disabled?: boolean
  style?: string
  onClick: (e?: any) => void
  type?: "button" | "submit" | "reset" | undefined
}

const Button = ({children, onClick, style, className, disabled, ...attrs}: React.PropsWithChildren<ButtonPropsType>) => {
  return (
      <button
          disabled={disabled}
          onClick={onClick}
          {...attrs}
          className={cx(style, {
            button: true,
            disabled: disabled,
          }, className)}>
        {children}
      </button>
  );
};

export default Button;
