import { makeAbbreviationIfLong } from '../utils/makeAbbreviation';

export const formatStatus = (status: string) => {
  switch (status) {
    case 'SUCCESS':
      return 'Success';
    case 'FAILURE':
      return 'Decline';
  }
  return makeAbbreviationIfLong(status);
};