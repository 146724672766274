import React, { useEffect, useState } from 'react';
import Title from '../../../components/Title/Title';
import './Events.scss';
import { RootState, useAppDispatch } from '../../../app/store';
import { useAppSelector } from '../../../app/hooks';
import { getAllEvents } from '../../../app/features/customers/customersSlice';
import { useParams } from 'react-router-dom';
import Loader from '../../../components/Loader/Loader';
import capitalizeFirstLetter from '../../../utils/capitalizeFirstLetter';
import Modal from '../../../components/Modal/Modal';
import Card from '../../../components/Card/Card';
import { getEmailCommsApi, getSmsCommsApi } from '../../../app/features/comms/commsAPI';
import { format } from 'date-fns';
import { json2html } from 'html2json';
import pdf from '../../../assets/pdf.svg';

const formatString = (str: string) => {
  if (str.indexOf(' ') >= 0) return str;
  const lowerStr = str.toLowerCase();
  if (lowerStr === 'sms') {
    return lowerStr.toUpperCase();
  }
  return capitalizeFirstLetter(lowerStr);
};

const Events = () => {

  const dispatch = useAppDispatch();
  const {id} = useParams();

  const {isLoading, events, customer} = useAppSelector((state: RootState) => state.customers);

  const [ showModal, setShowModal ] = useState(false);
  const [ modalType, setModalType ] = useState<'sms' | 'email'>('email');
  const [ data, setData ] = useState<any>();
  const [ isCommsLoading, setIsCommsLoading ] = useState(false);

  useEffect(() => {
    dispatch(getAllEvents(id));
  }, [ dispatch, id ]);

  const fetchCommsData = async(commsId: any, type: 'sms' | 'email') => {
    setModalType(type);
    setIsCommsLoading(true);
    const {data} = type === 'email' ? await getEmailCommsApi(id!, commsId) : await getSmsCommsApi(id!, commsId);
    setData(data);
    setIsCommsLoading(false);
  };

  if (isLoading) {
    return <Loader />;
  }

  const modalEmailInfo = {
    From: '',
    Date: '',
    Subject: '',
    To: '',
  };
  const modalSMSInfo = {
    Date: '',
    To: '',
  };

  const handleCommsModal = (commsId: any, type: 'sms' | 'email') => {
    fetchCommsData(commsId, type);
    setShowModal(true);
  };

  if (data && modalType === 'email') {
    modalEmailInfo.From = data.from;
    modalEmailInfo.Date = format(new Date(data.date), 'HH:mm MM/dd/yyyy');
    modalEmailInfo.Subject = data.subject;
    modalEmailInfo.To = customer.emailAddress;
  }
  if (data && modalType === 'sms') {
    modalSMSInfo.Date = format(new Date(data.date), 'HH:mm MM/dd/yyyy');
    modalSMSInfo.To = customer.mobilePhone;
  }

  return (
      <div className="events">
        <Title text={'Events.'} />
        <div className="events__table-container">
          <table className="events__table table">
            <thead className="table__head">
            <tr className="table__row">
              <th>Event</th>
              <th>Media</th>
              <th>User</th>
              <th>Status</th>
              <th>Date & time</th>
            </tr>
            </thead>
            <tbody className="table__body">
            {events.map((elem: any) => {
              return (
                  <tr key={elem.id} className="table__row">
                    <td>{elem.type === 'data' ?
                        elem.name :
                        <button onClick={() => handleCommsModal(elem.id, elem.type)}>{elem.name}</button>}</td>
                    <td>{formatString(elem.type)}</td>
                    <td>{formatString(elem.createdBy)}</td>
                    <td>{elem.type === 'data' ? 'Saved' : formatString('Sent')}</td>
                    <td>{format(new Date(elem.date), 'HH:mm dd/MM/y')}</td>
                  </tr>
              );
            })}
            </tbody>
          </table>
        </div>
        <Modal show={showModal} onClose={() => setShowModal(false)}>


          {isCommsLoading ? <Loader /> :
              modalType === 'sms' ? <Card className={`events__modal_sms`} color={'light'} size={'full'}>
                    <div className="modal__top">
                      {
                        Object.entries(modalSMSInfo).map(([ key, value ], idx) => {
                          return (
                              <p key={idx} className="modal__item">
                              <span className="modal__name">
                                {key}:
                              </span>
                                <span className="modal__info">
                                {value}
                              </span>
                              </p>
                          );
                        })
                      }
                    </div>
                    <div className="modal__body">
                      {data.message}
                    </div>
                  </Card>
                  : <Card className={`events__modal_email`} color={'light'} size={'full'}>
                    <div className="modal__top">
                      {
                        Object.entries(modalEmailInfo).map(([ key, value ], idx) => {
                          return (
                              <p key={idx} className="modal__item">
                              <span className="modal__name">
                                {key}:
                              </span>
                                <span className="modal__info">
                                {value}
                              </span>
                              </p>
                          );
                        })
                      }
                    </div>
                    <div className="modal__body">
                      {data &&
                          <div className="modal__text" dangerouslySetInnerHTML={{__html: json2html(data.body)}} />}
                      {data && data.attachments &&
                          <div className="modal__attachments">
                            {data.attachments.map((elem: any, idx: number) => {
                              return (
                                  <a href={elem.fileUrl} target={'_blank'} key={idx} className="modal__attachment">
                                    <img src={pdf} alt="pdf" />
                                    <p className="modal__filename">
                                      {elem.fileName}
                                    </p>
                                  </a>
                              );
                            })}
                          </div>}

                    </div>
                  </Card>
          }
        </Modal>
      </div>
  );
};

export default React.memo(Events);
