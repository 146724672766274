import React from 'react';
import './Comms.scss';
import CustomSelect from '../CustomSelect/CustomSelect';
import Title from '../Title/Title';
import BtnGroup from '../BtnGroup/BtnGroup';
import Editor from '../TextEditor/TextEditor';

const SmsContainer = ({
  options,
  selectedOption,
  setSelectedOption,
  handleCancel,
  handleSend,
  content,
  contentTouched,
  isLoading,
  isSent,
  setContent,
  editorRef,
  className,
}: any) => {

  return (
      <div className={`comms-group ${className}`}>
        <Title text={'SMS.'} />
        <div className={`comms-group__body`}>
          <CustomSelect
              label={'Template'}
              optionsList={options}
              onChange={setSelectedOption}
              selectedOption={selectedOption}
              className={`comms-group__field`}
              placeholder={'Select a template'} />
          <Editor content={content}
              className={'templates__editor'}
              label={'Content'}
              output={'text'}
              ref={editorRef}
              onChange={setContent} />
        </div>
        <div className={`comms-group__bottom-block`}>
          <div />
          <BtnGroup
              isActive={contentTouched}
              activeBtnName={'Send'}
              onClick={handleSend}
              onCancel={handleCancel}
              isLoading={isLoading}
              isSuccess={isSent}
              successMsg={'Sent'}
          />
        </div>
      </div>
  );
};

export default SmsContainer;
