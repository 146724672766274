import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getCustomerByIdAPI,
  updateLoanAPI,
  updateInfoAPI,
  getEventsAPI, assignAPI, shareAPI,
} from './customersAPI';
import { showToast } from '../ui/uiSlice';
import { RootState } from '../../store';
import { isEmpty } from '../../../utils/isEmpty';

export type AssigneeType = {
  userId: string
  fullName: string
}

export type CustomerType = {
  residentialStatus: string | null;
  paymentDay: number | null;
  firstPaymentDate: string | null;
  actualLoan: LoanType
  originalLoan: LoanType
  alternativeLoan: LoanType
  brokerId: null | number,
  sortCode: string | null
  accountNumber: string | null
  apr: number
  userId: string,
  status: string,
  failureReason: string,
  title: string,
  firstName: string,
  lastName: string,
  fullName: string,
  emailAddress: string,
  mobilePhone: string,
  loanPurpose: string,
  createdAt: string,
  dob: string,
  agreementNumber: string,
  bankName: string,
  assignee: null | AssigneeType,
  applicationStatus: null | string,
  criteriaStatus: null | string,
  applicationStage: null | string,

  addresses: {
    current: AddressType
    previous: AddressType
  }
}

export type AddressType = {
  address1: string,
  address2: string,
  address3: string,
  townOrCity: string,
  postCode: string,
  county: string,
}

export type LoanType = {
  amount: number | null,
  term: number | null,
  cost: number | null,
  repayment: number | null,
  total: number | null,
  apr: number | null,
}

export type EventType = {
  event: string,
  createdAt: string,
  sms_comms_id: number | null,
  email_comms_id: number | null
  createdBy: string,
}

export type ReportType = {
  credit: {} | null
  sanctions: {} | null
  affordability: {} | null
}

type StateType = {
  isLoading: boolean
  isSuccess: boolean
  isModalLoading: boolean
  customer: any
  events: EventType[] | []
  reports: ReportType
  error: null | string
}

export const customersSlice = createSlice({
  name: 'customers',
  initialState: {
    isLoading: false,
    isSuccess: false,
    isModalLoading: false,
    customer: {
      originalLoan: {
        amount: null,
        term: null,
        cost: null,
        repayment: null,
        total: null,
        apr: null,
      },
      alternativeLoan: {
        amount: null,
        term: null,
        cost: null,
        repayment: null,
        total: null,
        apr: null,
      },
      sortCode: null,
      accountNumber: null,
      brokerId: null,
      userId: '',
      status: '',
      failureReason: '',
      title: '',
      firstName: '',
      lastName: '',
      fullName: '',
      emailAddress: '',
      mobilePhone: '',
      loanPurpose: '',
      createdAt: '',
      dob: '',
      agreementNumber: '',
      bankName: '',
      assignee: null,
      applicationStatus: null,
      criteriaStatus: null,
      applicationStage: null,
      addresses: {
        current: {
          address1: '',
          address2: '',
          address3: '',
          townOrCity: '',
          postCode: '',
          county: '',
        },
        previous: {
          address1: '',
          address2: '',
          address3: '',
          townOrCity: '',
          postCode: '',
          county: '',
        },
      },
    },
    events: [],
    reports: {
      credit: null,
      sanctions: null,
      affordability: null,
    },
    error: null,
  } as StateType,
  reducers: {
    setStatus: (state, action) => {
      state.customer.applicationStatus = action.payload.applicationStatus;
      state.customer.criteriaStatus = action.payload.criteriaStatus;
      state.customer.applicationStage = action.payload.applicationStage;
    },
    setSuccess: (state, action) => {
      state.isSuccess = action.payload;
    },
    setSanctions: (state, action) => {
      state.reports.sanctions = action.payload;
    },
    setCredit: (state, action) => {
      state.reports.credit = action.payload;
    },
    setAffordability: (state, action) => {
      state.reports.affordability = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCustomerById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCustomerById.fulfilled, (state, {payload}) => {
      state.isLoading = false;
      state.customer = payload.customer;
      state.reports.affordability = payload.reports.affordability;
      state.reports.sanctions = payload.reports.sanctions;
      state.reports.credit = payload.reports.credit;
    });
    builder.addCase(getCustomerById.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getAllEvents.fulfilled, (state, {payload}) => {
      state.events = payload;
    });
    builder.addCase(assignCustomer.fulfilled, (state, {payload}) => {
      state.customer = payload;
    });
    builder.addCase(updateLoan.pending, (state) => {
      state.isModalLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(updateLoan.fulfilled, (state, {payload}) => {

      const hasAlternativeLoan = !isEmpty && !Object.values(state.customer.alternativeLoan).some(v => v);
      state.isModalLoading = false;
      if (hasAlternativeLoan) {
        state.customer = {...state.customer, alternativeLoan: payload};
      } else {
        state.customer = {...state.customer, originalLoan: payload};
      }
      state.isSuccess = true;
    });
    builder.addCase(updateLoan.rejected, (state) => {
      state.isModalLoading = false;
    });
    builder.addCase(updateInfo.pending, (state) => {
      state.isModalLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(updateInfo.fulfilled, (state, {payload}) => {
      state.isModalLoading = false;
      state.isSuccess = true;
      let mergedAddresses = state.customer.addresses;
      if (payload.hasOwnProperty('addresses')) {
        mergedAddresses = {...state.customer.addresses, ...payload.addresses};
        delete payload.addresses;
      }
      state.customer = {...state.customer, ...payload, addresses: mergedAddresses};
    });
    builder.addCase(updateInfo.rejected, (state) => {
      state.isModalLoading = false;
    });
  },
});

export const getAllEvents = createAsyncThunk('customers/getAllEvents', async(id: any, {dispatch, rejectWithValue}) => {
  try {
    const response = await getEventsAPI(id);
    return response.data;
  } catch (err: any) {
    dispatch(showToast({message: err.response.data.message, severity: 'failed'}));
    return rejectWithValue(err.response.data);
  }
});
export const getCustomerById = createAsyncThunk('customers/getById', async(id: any, {dispatch, rejectWithValue}) => {
  try {
    const response = await getCustomerByIdAPI(id);
    return response.data;
  } catch (err: any) {
    dispatch(showToast({message: err.response.data.message, severity: 'failed'}));
    return rejectWithValue(err.response.data);
  }
});

export const updateLoan = createAsyncThunk('customers/updateLoan', async(data: any, {dispatch, getState, rejectWithValue}) => {
  try {
    const id = (getState() as RootState).customers.customer.userId;
    const response = await updateLoanAPI(id, data);
    return response.data;
  } catch (err: any) {
    dispatch(showToast({message: err.response.data.message, severity: 'failed'}));
    return rejectWithValue(err.response.data.message);
  }
});
export const updateInfo = createAsyncThunk('customers/updateDetails', async(data: any, {dispatch, getState, rejectWithValue}) => {
  try {
    const id = (getState() as RootState).customers.customer.userId;
    const response = await updateInfoAPI(id, data);
    return response.data;
  } catch (err: any) {
    dispatch(showToast({message: err.response.data.message, severity: 'failed'}));
    return rejectWithValue(err.response.data.message);
  }
});
export const shareCustomer = createAsyncThunk('customers/share', async(notifyId: string, {dispatch, getState, rejectWithValue}) => {
  try {
    const id = (getState() as RootState).customers.customer.userId;
    const response = await shareAPI({id, notifyId});
    return response.data;
  } catch (err: any) {
    dispatch(showToast({message: err.response.data.message, severity: 'failed'}));
    return rejectWithValue(err.response.data.message);
  }
});
export const assignCustomer = createAsyncThunk('customers/assign', async(notifyId: any, {dispatch, getState, rejectWithValue}) => {
  try {
    const id = (getState() as RootState).customers.customer.userId;
    const response = await assignAPI({id, notifyId});
    return response.data;
  } catch (err: any) {
    dispatch(showToast({message: err.response.data.message, severity: 'failed'}));
    return rejectWithValue(err.response.data.message);
  }
});

export const {setSuccess, setStatus, setCredit, setSanctions, setAffordability} = customersSlice.actions;

export default customersSlice.reducer;
