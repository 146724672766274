import React from 'react';
import Modal from '../Modal/Modal';
import VerificationCard from '../profileComponents/VerificationCard/VerificationCard';
import AffordabilityCard from '../profileComponents/AffordabilityCard/AffordabilityCard';
import CreditRiskCard from '../profileComponents/CreditRiskCard/CreditRiskCard';
import AccountScoreCard from '../profileComponents/AccountScoreCard/AccountScoreCard';
import CallReportCard from '../profileComponents/CallReportCard/CallReportCard';
import ApplicantCardModal from '../modals/ApplicantCardModal/ApplicantCardModal';
import AssignModal from '../modals/AssignModal/AssignModal';
import LoanModal from '../modals/LoanModal/LoanModal';
import ApplicantData from '../modals/ApplicantDataModal/ApplicantData';
import { ReportType, CustomerType } from '../../app/features/customers/customersSlice';
import EmailChainModal from '../CommsModals/EmailModal/EmailModal';
import SmsChainModal from '../CommsModals/SmsModal/SmsModal';

type ModalManagerPropsType = {
  reports: ReportType
  customer: any
  handleCloseModal: () => void
  modalId: number | null
}

const ModalManager = ({
  reports,
  customer,
  handleCloseModal = () => null,
  modalId = null,
}: ModalManagerPropsType) => {

  return (
      <>
        <Modal show={modalId === 1} onClose={handleCloseModal}>
          <VerificationCard display="full" report={reports.sanctions} className={'profile__card'} />
        </Modal>
        <Modal show={modalId === 2} onClose={handleCloseModal}>
          <AffordabilityCard display="full" className={'profile__card'} report={reports.affordability} />
        </Modal>
        <Modal show={modalId === 3} onClose={handleCloseModal}>
          <CreditRiskCard display="full" className={'profile__card'} report={reports.credit} />
        </Modal>
        <Modal show={modalId === 4} onClose={handleCloseModal}>
          <AccountScoreCard display="full" className={'profile__card'} report={reports.affordability} />
        </Modal>
        <Modal show={modalId === 5} onClose={handleCloseModal}>
          <CallReportCard display="full" className={'profile__card'} report={reports.credit} />
        </Modal>
        <Modal show={modalId === 6} onClose={handleCloseModal}>
          <ApplicantCardModal
              title={customer.title}
              firstName={customer.firstName}
              lastName={customer.lastName}
              dob={customer.dob}
          />
        </Modal>
        <Modal show={modalId === 7} onClose={handleCloseModal}>
          <AssignModal
              assignedUser={customer.assignee}
              onClose={handleCloseModal}
          />
        </Modal>
        <Modal show={modalId === 8} onClose={handleCloseModal}>
          <LoanModal
              loanPurpose={customer.loanPurpose}
              originalLoan={customer.loans?.originalLoan}
              alternativeLoan={customer.loans?.alternativeLoan}
              applicationStatus={customer.applicationStatus}
              paymentDay={customer.paymentDay}
              firstPaymentDate={customer.firstPaymentDate}
          />
        </Modal>
        <Modal show={modalId === 9} onClose={handleCloseModal}>
          <ApplicantData
              emailAddress={customer.emailAddress}
              mobilePhone={customer.mobilePhone}
              postCode={customer.addresses.current.postCode}
              prevPostCode={customer.addresses.previous?.postCode}
              sortCode={customer.sortCode}
              accountNumber={customer.accountNumber}
              residentialStatus={customer.residentialStatus}
          />
        </Modal>
        <EmailChainModal visible={modalId === 10} onClose={handleCloseModal}/>
        <SmsChainModal visible={modalId === 11} onClose={handleCloseModal}/>
      </>
  );
};

export default ModalManager;
