import CustomAxios from '../../CustomAxios/CustomAxios';

export const getUsersAPI = () => {
  return CustomAxios.get(`${process.env.REACT_APP_USERS_URL}/dev/users`);
}

export const addUserAPI = (data:any) => {
  return CustomAxios.post(`${process.env.REACT_APP_USERS_URL}/dev/users`, data);
}

export const deleteUserAPI = (id:string) => {
  return CustomAxios.delete(`${process.env.REACT_APP_USERS_URL}/dev/users/${id}`);
}

export const resetPasswordAPI = (id:string) => {
  return CustomAxios.get(`${process.env.REACT_APP_USERS_URL}/dev/users/reset/${id}`);
}