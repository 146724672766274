import React from 'react';
import './Comms.scss';
import CustomSelect from '../CustomSelect/CustomSelect';
import Input from '../input/Input';
import Checkbox from '../Checkbox/Checkbox';
import Title from '../Title/Title';
import Editor from '../TextEditor/TextEditor';
import BtnGroup from '../BtnGroup/BtnGroup';

const EmailContainer = ({
  options,
  subject,
  setSubject,
  selectedOption,
  setSelectedOption,
  useSignature,
  setUseSignature,
  handleCancel,
  content,
  setContent,
  className,
  editorRef,
  handleSend,
  isLoading,
  isSent,
  contentTouched,
}: any) => {

  return (
      <div className={`comms-group ${className}`}>
        <Title text={'Email.'} />
        <div className={`comms-group__body`}>
          <CustomSelect
              label={'Template'}
              optionsList={options}
              onChange={setSelectedOption}
              selectedOption={selectedOption}
              className={`comms-group__field`}
              placeholder={'Select a template'} />
          <Input value={subject} onChange={(e) => setSubject(e.currentTarget.value)} className={`comms-group__field`} label={'Subject'} />
          <Editor content={content}
              className={'templates__editor'}
              label={'Content'}
              ref={editorRef}
              onChange={setContent} />
        </div>
        <div className={`comms-group__bottom-block`}>
          <Checkbox checked={useSignature} label={'Signature'} onChange={setUseSignature} />
          <BtnGroup
              isActive={contentTouched}
              activeBtnName={'Send'}
              onClick={handleSend}
              onCancel={handleCancel}
              isLoading={isLoading}
              isSuccess={isSent}
              successMsg={'Sent'}
          />
        </div>
      </div>
  );
};

export default EmailContainer;