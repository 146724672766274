import React from 'react';
import { Route } from 'react-router-dom';
import Dashboard from '../../pages/Dashboard/Dashboard';
import LeadsTable from '../../pages/LeadsTable/LeadsTable';
import AppsTable from '../../pages/AppsTable/AppsTable';
import SuccessTable from '../../pages/SuccessTable/SuccessTable';
import PaidoutTable from '../../pages/PaidoutTable/PaidoutTable';
import IntroducedTable from '../../pages/IntroducedTable/AppsTable';
import CustomersLayout from '../../components/CustomersLayout/CustomersLayout';
import CustomersSearch from '../../pages/CustomersSearch/CustomersSearch';
import SearchResultsTable from '../../pages/SearchResultsTable/SearchResultsTable';
import CustomerProfile from '../../pages/CustomerProfile/CustomerProfile';
import Events from '../../pages/CustomerProfile/Events/Events';
import JourneyPage from '../../pages/JourneyPage/JourneyPage';
import EmailPage from '../../pages/CustomerProfile/Comms/EmailPage';
import SmsPage from '../../pages/CustomerProfile/Comms/SmsPage';
import FundedLoans from '../../pages/FundedLoans/FundedLoans';
import ManagementInformation from '../../pages/ManagementInformation/ManagementInformation';
import JourneyChart from '../../components/JourneyChart/JourneyChart';
import Profile from '../../pages/Profile/Profile';
import Users from '../../pages/Users/Users';
import TemplatesPage from '../../pages/TemplatesPage/TemplatesPage';
import NotificationPage from '../../pages/NotificationPage/NotificationPage';


const authRequiredRoutes = (theme: string, setTheme: any) => {
  return (
    <>
        <Route path={'dashboards'}>
          <Route index element={<Dashboard theme={theme} setTheme={setTheme} />} />
          <Route path={'leads'} element={<LeadsTable />} />
          <Route path={'apps'} element={<AppsTable />} />
          <Route path={'success'} element={<SuccessTable />} />
          <Route path={'paidout'} element={<PaidoutTable />} />
          <Route path={'introduced'} element={<IntroducedTable />} />
        </Route>
        <Route path={'customers'} element={<CustomersLayout />}>
          <Route index element={<CustomersSearch />} />
          <Route path={'search'} element={<SearchResultsTable />} />
          <Route path={':id'} element={<CustomerProfile />} />
          <Route path={':id/events'} element={<Events />} />
          <Route path={':id/journey'} element={<JourneyPage />} />
          <Route path={':id/email'} element={<EmailPage />} />
          <Route path={':id/sms'} element={<SmsPage />} />
        </Route>
        <Route path={'reports'}>
          <Route index element={<FundedLoans />} />
          <Route path={'management-information'} element={<ManagementInformation />} />
          <Route path={'journey'} element={<JourneyChart/>} />
        </Route>
        <Route path={'settings'}>
          <Route index element={<Profile />} />
          <Route path={'users'} element={<Users />} />
          <Route path={'templates'} element={<TemplatesPage />} />
        </Route>
        <Route path={'notifications'} element={<NotificationPage />} />
    </>
  );
};
export default authRequiredRoutes;
