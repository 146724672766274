export const formatValueToCurrency = (value: string | number | null) => {
  if (!value) {
    return '£' + 0;
  }
  if (typeof value === 'string') {
    return '£' + value;
  }
  return Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  }).format(value);
};