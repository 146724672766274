import React from 'react';
import CardContainer from '../../CardContainer/CardContainer';
import cx from 'classnames';
import './AffordabilityCard.scss';
import { formatMoney } from '../../../utils/formatMoney';
import { IndicatorStatus } from '../../Indicator/Indicator';

interface IProps {
  onClick?: () => void,
  className: string,
  report: any,
  display?: 'full' | 'combined' | 'limited'
}

const AffordabilityCard = ({onClick, report, className, display = 'combined'}: IProps) => {

  const isDataExist = !!report;

  const {income, expenditures} = report?.budgetPlan || {};

  const incomeToHuman = {
    'Identified salary': income?.salary,
    'Regular transfers in': income?.regularTransfersIn,
    'Irregular transfers in': income?.irregularTransfersIn,
    'Cash in': income?.cashIn,
    'Other income': income?.otherIncome,
  };
  const expendituresToHuman = {
    'Rent/mortgage': expenditures?.rent,
    'Travel': expenditures?.travel,
    'Bills & services': expenditures?.billsAndServices,
    'Food': expenditures?.food,
    'Repayments on existing debts': expenditures?.repaymentsOnExistingDebts,
    'Regular cash out': expenditures?.regularCashOut,
    'Regular transfers out': expenditures?.regularTransfersOut,
    'Takeaways & eating out': expenditures?.takeAways,
    'Clothing & footwear': expenditures?.clothing,
    'Familio safety buffer': expenditures?.safetyBuffer,
    'Repayments on this loan': expenditures?.loanRepayment,
  };


  const totalIncome = Object.entries(incomeToHuman).reduce((acc: any, [ _, value ]: any) => acc + value, 0);
  const totalExpenditure = Object.entries(expendituresToHuman).reduce((acc: any, [ _, value ]: any) => acc + value, 0)

  const isGreen = totalIncome - totalExpenditure >= 0;

  const status = isGreen ? IndicatorStatus.SUCCESS : IndicatorStatus.FAIL;

  if (display === 'full') {
    return (
        <CardContainer onClick={onClick}
            title={'Budget plan'}
            comment={'The applicant’s financial position'}
            className={cx(className, 'affordability', {})}
            status={status}>
          <div className="affordability__block">
            <div className="affordability__block_total-sum">
              <div className="affordability__flex">
                <p className="affordability__block_item">
                  Income
                </p>
                <p className="affordability__block_item">
                  {formatMoney(totalIncome)}
                </p>
              </div>
            </div>
            <div className="affordability__block_categories">
              {
                Object.entries(incomeToHuman).map(([ key, value ]: any, idx: number) => {
                  if (!value) return;
                  return (
                      <div key={idx} className="affordability__flex">
                        <p className="affordability__block_item">
                          {key}
                        </p>
                        <p className="affordability__block_item">
                          {formatMoney(value)}
                        </p>
                      </div>
                  );
                })
              }
            </div>
          </div>
          <div className="affordability__block">
            <div className="affordability__block_total-sum">
              <div className="affordability__flex">
                <p className="affordability__block_item">
                  Expenditure
                </p>
                <p className="affordability__block_item">
                  {formatMoney(totalExpenditure)}
                </p>
              </div>
            </div>
            <div className="affordability__block_categories">
              {
                Object.entries(expendituresToHuman).map(([ key, value ]: any, idx: number) => {
                  return (
                      <div key={idx} className="affordability__flex">
                        <p className="affordability__block_item">
                          {key}
                        </p>
                        <p className="affordability__block_item">
                          {formatMoney(value)}
                        </p>
                      </div>
                  );
                })
              }
            </div>
          </div>
          <div className="affordability__block">
            <div className="affordability__block_total-sum">
              <div className="affordability__flex">
                <p className="affordability__block_item">
                  Budget
                </p>
                <p
                    className={`affordability__block_item ${isGreen ? 'green' : 'red'}`}>
                  {formatMoney(totalIncome - totalExpenditure)}
                </p>
              </div>
            </div>
          </div>
        </CardContainer>
    );
  }
  return (
      <>
        <CardContainer
            {...(isDataExist && {onClick: onClick})}
            {...(isDataExist && {status})}
            title={'Budget plan'}
            comment={'AFFORDABILITY DATA'}
            className={cx(className, {
              'limited': display === 'limited',
            })}>
          {isDataExist && display !== 'limited' &&
              <>
                <h1
                    className={`card__info ${isGreen ? 'green' : 'red'}`}>
                  {formatMoney(totalIncome - totalExpenditure)}
                </h1>
                <div className="card__flex">
                  <p className="card__text">
                    Income
                  </p>
                  <p className="card__text">
                    {formatMoney(totalIncome)}
                  </p>
                </div>
                <div className="card__flex">
                  <p className="card__text">
                    Expenditure
                  </p>
                  <p className="card__text">
                    {formatMoney(totalExpenditure)}
                  </p>
                </div>
              </>
          }
        </CardContainer>
      </>
  );
};

export default AffordabilityCard;
