import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist/es/constants';
import storage from 'redux-persist/lib/storage';
import authReducer from '../app/features/auth/authSlice';
import customersReducer from '../app/features/customers/customersSlice';
import reportsReducer from '../app/features/reports/reportsSlice';
import usersReducer from '../app/features/users/usersSlice';
import { uiReducer } from './features/ui/uiSlice';
import { useDispatch } from 'react-redux';
import { userReducer } from './features/user/userSlice';
import { templatesReducer } from './features/templates/templatesSlice';
import { commandsReducer } from './features/commands/commandsSlice';
import { notificationReducer } from './features/notifications/notificationSlice';
import notificationMiddleware from './middleware/notificationMiddleware';
import { latestStatsReducer } from './features/currentStats/currentStatsSlice';
import { commsReducer } from './features/comms/commsSlice';

const persistConfig = {
  key: 'root',
  storage,
};

export const rootReducer = combineReducers({
    auth: authReducer,
    commands: commandsReducer,
    templates: templatesReducer,
    user: userReducer,
    customers: customersReducer,
    reports: reportsReducer,
    users: usersReducer,
    ui: uiReducer,
    notifications: notificationReducer,
    latestStats: latestStatsReducer,
    comms: commsReducer,
  })

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(notificationMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch: () => AppDispatch = useDispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export const persistor = persistStore(store);