import { Middleware } from 'redux';
import { w3cwebsocket as Websocket, w3cwebsocket } from 'websocket';
import { connectionEstablished, receiveNotifications, startConnecting } from '../features/notifications/notificationSlice';

const notificationMiddleware: Middleware = store => {
  let ws: w3cwebsocket;

  return next => action => {
    if (startConnecting.match(action)) {
      const state: any = store.getState();
      const userId = state.user.user.id;
      ws = new Websocket(`${process.env.REACT_APP_NOTIFICATIONS_WS_URL}/dev?userId=${userId}`);
      ws.onopen = () => {
        store.dispatch(connectionEstablished());
        ws.send(JSON.stringify({action: 'getAll'}));
      };
      ws.onmessage = (message) => {
        if (typeof message.data === 'string') {
          const data = JSON.parse(message.data);
          store.dispatch(receiveNotifications(data));
        }
      };
    }
    next(action);
  };
};

export default notificationMiddleware;