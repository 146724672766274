import React, { useEffect } from 'react';
import './Homepage.scss';
import Card from '../../components/Card/Card';
import CardContainer from '../../components/CardContainer/CardContainer';
import Title from '../../components/Title/Title';
import CustomLink from '../../components/CustomLink/CustomLink';
import { RootState, useAppDispatch } from '../../app/store';
import { useAppSelector } from '../../app/hooks';
import { markNotificationAsRead } from '../../app/features/notifications/notificationSlice';
import { formatNotifications } from '../../utils/formatNotifications';
import { format } from 'date-fns';
import { CustomerStatsType, getLatestStats } from '../../app/features/currentStats/currentStatsSlice';
import { formatStatus } from '../../helpers/formatStatus';
import { Link } from 'react-router-dom';

type HomepageCardProps = {
  comment: string
  title: string
  customers: CustomerStatsType[]
}

const HomepageCard = ({comment, title, customers}: HomepageCardProps) => {
  return (
      <CardContainer className={'homepage__card card'} title={title} comment={comment}>
        <ul className="card__list">
          {customers.map((elem: CustomerStatsType) => (
              <li key={elem.userId} className="card__item">
                <CustomLink to={`/customers/${elem.userId}`}>{elem.fullName}</CustomLink>
                <span>{elem.applicationStatus && formatStatus(elem.applicationStatus)}</span>
              </li>
          ))}
        </ul>
      </CardContainer>
  );
};

const Homepage = () => {

  const dispatch = useAppDispatch();
  const {notifications} = useAppSelector((state: RootState) => state.notifications);

  const {stats} = useAppSelector((state: RootState) => state.latestStats);
  useEffect(() => {
    dispatch(getLatestStats());
    const interval = setInterval(() => dispatch(getLatestStats()), 30000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleNotificationClick = (id: number, isRead: boolean) => {
    if (isRead) return;
    dispatch(markNotificationAsRead(id));
  };

  const formattedNotifications = formatNotifications(notifications);

  console.log(formattedNotifications)

  return (
      <div className="homepage">
        <Title text={'The latest.'} />
        <div className="homepage__top-block">
          <HomepageCard title={'Leads'} customers={stats.latestCustomers.leads} comment={'All customers'} />
          <HomepageCard title={'Apps'} customers={stats.latestCustomers.apps} comment={'Completed applications'} />
          <HomepageCard title={'Success'} customers={stats.latestCustomers.allSuccesses} comment={'Successful applications'} />
        </div>
        <Title text={'Today at a glance.'} />
        <div className="homepage__bottom-block">
          <div className="homepage__cards cards">
            <Link to={'/dashboards/leads'}>
              <Card size={'sm'} color={'dark'}>
                <div className="cards__wrapper">
                   <span className="cards__title">
                  Leads
                </span>
                  <span className="cards__number">
                  {stats.totalToday.leads}
                </span>
                </div>
              </Card>
            </Link>
            <Link to={'/dashboards/apps'}>
              <Card size={'sm'} color={'dark'}>
                <div className="cards__wrapper">
                   <span className="cards__title">
                  Apps
                </span>
                  <span className="cards__number">
                  {stats.totalToday.apps}
                </span>
                </div>
              </Card>
            </Link>
            <Link to={'/dashboards/success'}>
              <Card size={'sm'} color={'dark-grey'}>
                <div className="cards__wrapper">
                   <span className="cards__title">
                  Success
                </span>
                  <span className="cards__number">
                  {stats.totalToday.success}
                </span>
                </div>
              </Card>
            </Link>
            <Link to={'/dashboards/introduced'}>
              <Card size={'sm'} color={'dark-grey'}>
                <div className="cards__wrapper">
                   <span className="cards__title">
                  Introduced
                </span>
                  <span className="cards__number">
                  {stats.totalToday.introduced}
                </span>
                </div>
              </Card>
            </Link>
          </div>
          <div className="homepage__alerts alerts">
            <CardContainer comment={'Alerts & significant events'} size={'full'} title={'Notifications'}>
              <table className="alerts__table">
                <tbody className="alerts__body">
                {formattedNotifications.length && formattedNotifications.map((elem: any) => {
                  return (
                      <tr key={elem.id} className="alerts__row">
                        <td className={elem.isRead ? '' : 'unread'}></td>
                        <td><CustomLink onClick={() => handleNotificationClick(elem.id, elem.isRead)}
                            to={`/customers/${elem.borrower.userId}`}>{elem.borrower.fullName}</CustomLink></td>
                        <td>{elem.borrower.applicationStatus && formatStatus(elem.borrower.applicationStatus)}</td>
                        <td>{elem.notification}</td>
                        <td>{format(new Date(elem.date), 'dd/MM/y')}</td>
                      </tr>
                  );
                })}
                </tbody>
              </table>
            </CardContainer>
          </div>
        </div>
      </div>
  );
};

export default Homepage;
