import React from 'react';
import './Search.scss';
import { useNavigate } from 'react-router-dom';

const Search = () => {

  const [ value, setValue ] = React.useState('');

  const navigate = useNavigate();

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      navigate(`search?q=${value}`);
    }
  };

  return (
      <div className={`searchBar `}>
        <form className="searchBar__form">
          <input className="searchBar__input" value={value}
              // onBlur={onBlur}
              autoFocus={true}
              onKeyDown={handleKeyDown}
              onChange={(e: any) => setValue(e.currentTarget.value)} type="search" />
        </form>
      </div>
  );
};

export default Search;
