import moment from 'moment';

export function handleDob(dob: string | undefined, now: Date) {
  const eighteenYearsAgo = moment(now).subtract(18, 'years');
  const twentyEightYearsAgo = moment(now).subtract(28, 'years');
  const custBirthday = moment(dob);
  if (custBirthday.isSame(eighteenYearsAgo) || custBirthday.isAfter(eighteenYearsAgo)) {
    return false;
  } else if (custBirthday.isSame(twentyEightYearsAgo) || custBirthday.isBefore(twentyEightYearsAgo)) {
    return false;
  }
  return true;
}