import React, { useEffect, useState } from 'react';
import CustomersTableContainer, { DateType } from '../../components/CustomersTableContainer/CustomersTableContainer';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { clearReport, getSuccess } from '../../app/features/reports/reportsSlice';
import { format } from 'date-fns';
import { RootState } from '../../app/store';

const SuccessTable = () => {
  const dispatch = useAppDispatch();
  const {report, isLoading} = useAppSelector((state: RootState) => state.reports);

  const today = new Date();
  const [ date, selectedDate ] = useState<DateType>({
    rangeName: 'Today',
    from: today,
    to: today,
  });

  let name = 'Today';

  if (date && date.rangeName) {
    name = date.rangeName;
  }

  const handleSelectedDate = (date:DateType) => {
    selectedDate(date)
  }

  useEffect(() => {
    dispatch(getSuccess({
      startDate: format(date.from, 'y/MM/dd'),
      endDate: format(date.to, 'y/MM/dd'),
    }));
    return () => {
      dispatch(clearReport());
    };
  }, [ date ]);

  return <CustomersTableContainer isLoading={isLoading}
      title={'Success.'}
      data={report}
      date={date}
      selectedDate={handleSelectedDate}
      dropdownName={name} />;

};

export default SuccessTable;
