import CustomAxios from '../../CustomAxios/CustomAxios';

export type PayloadSendEmailType = {
  content: Object
  subject: string
}

export type PayloadSendSmsType = {
  message: string
}

export const sendEmailAPI = (id: string, data: PayloadSendEmailType) => {
  return CustomAxios.post(`${process.env.REACT_APP_CUSTOMERS_URL}/dev/customer/comms/${id}/email`, data);
};
export const sendSmsAPI = (id: string, data: PayloadSendSmsType) => {
  return CustomAxios.post(`${process.env.REACT_APP_CUSTOMERS_URL}/dev/customer/comms/${id}/sms`, data);
};
export const getEmailCommsApi = (id: string, item: number) => {
  return CustomAxios.get(`${process.env.REACT_APP_CUSTOMERS_URL}/dev/customer/comms/${id}/email?eventId=${item}`);
};
export const getSmsCommsApi = (id: string, item: number) => {
  return CustomAxios.get(`${process.env.REACT_APP_CUSTOMERS_URL}/dev/customer/comms/${id}/sms?eventId=${item}`);
};