import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { showToast } from '../ui/uiSlice';
import { getCurrentStatsAPI } from './currentStatsAPI';

export interface IStatsState {
  stats: StatsType;
  isLoading: boolean;
}

export type CustomerStatsType = {
  userId: string
  fullName: string
  applicationStage: string
  applicationStatus: string
  loanAmount: number
}
export type StatsType = {
  latestCustomers: {
    apps: CustomerStatsType[] | []
    leads: CustomerStatsType[] | []
    success: CustomerStatsType[] | []
    loan: CustomerStatsType[] | []
    allSuccesses: CustomerStatsType[] | []
  },
  totalToday: {
    introduced: number;
    credit: number,
    affordability: number,
    validation: number,
    apps: number,
    success: number,
    leads: number,
    loan: number
  }
}
const initialState: IStatsState = {
  stats: {
    latestCustomers: {
      apps: [],
      leads: [],
      success: [],
      loan: [],
      allSuccesses: [],
    },
    totalToday: {
      credit: 0,
      affordability: 0,
      validation: 0,
      apps: 0,
      success: 0,
      leads: 0,
      loan: 0,
      introduced: 0,
    },
  },
  isLoading: false,
};

const slice = createSlice({
  name: 'currentStats',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLatestStats.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getLatestStats.fulfilled, (state, {payload}) => {
      state.stats = payload;
      state.isLoading = false;
    });
    builder.addCase(getLatestStats.rejected, (state) => {
      state.isLoading = false;
    });
  },
});
export const getLatestStats = createAsyncThunk('currentStats/getLatestStats', async(data, {dispatch, rejectWithValue}) => {
  try {
    const response = await getCurrentStatsAPI();
    return response.data;
  } catch (err: any) {
    dispatch(showToast({message: err.response.data.message, severity: 'failed'}));
    return rejectWithValue(err.response.data.message);
  }
});

export const latestStatsReducer = slice.reducer;