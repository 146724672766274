import React from 'react';
import loader from '../../../assets/loader.gif';
import { Link } from 'react-router-dom';
import { SubmenuType } from './Menu';

export type MenuDropdownPropsType = {
  key: number
  submenus: SubmenuType[]
  dropdown: boolean
  isLoading: boolean
}

const MenuDropdown = ({key, dropdown, submenus, isLoading}: MenuDropdownPropsType) => {

  const [ selectedItem, setSelectedItem ] = React.useState<string>('');

  const handleClick = async(action: Function, item: string) => {
    setSelectedItem(item);
    action(key);
  };

  return (
      <ul className={`menu__dropdown ${dropdown ? 'show' : ''}`}>
        {submenus.map((submenu: any, index: any) => (
            <li key={index} className="menu__item_submenu">
              {submenu.id
                  ? isLoading && selectedItem === submenu.id
                      ?
                      <div className="menu__btn_loader"><img src={loader} alt="loader" /></div>
                      :
                      <button onClick={() => handleClick(submenu.action, submenu.id)} className="menu__btn_submenu">{submenu.title}</button>
                  : <Link className="menu__btn_submenu" to={submenu.url}>{submenu.title}</Link>
              }
            </li>
        ))}
      </ul>
  );
};

export default MenuDropdown;
