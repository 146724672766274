const anyMatch = (lookingFor: any, ...args: any) => {
  for (let i = 0; i < args.length; i += 1) {
    if (lookingFor === args[ i ]) return true;
  }
  return false;
};

export function between(value: any, start: any, end: any) {
  return value >= start && value <= end;
}

export function fullSortcodesMatch(sortcodeParts: any, secondSortcodeParts: any) {
  return (
      sortcodeParts[ 0 ] === secondSortcodeParts[ 0 ] &&
      sortcodeParts[ 1 ] === secondSortcodeParts[ 1 ] &&
      sortcodeParts[ 2 ] === secondSortcodeParts[ 2 ]
  );
}

export function anyFullSortcodesMatch(sortcodeParts: any, ...cantMatch: any) {
  for (let i = 0; i < cantMatch.length; i += 1) {
    if (fullSortcodesMatch(sortcodeParts, cantMatch[ i ])) {
      return true;
    }
  }

  return false;
}

export function sortcodeToInts(sortcodeStr: string) {
  return [
    parseInt(sortcodeStr.slice(0, 2), 10),
    parseInt(sortcodeStr.slice(2, 4), 10),
    parseInt(sortcodeStr.slice(4, 6), 10),
  ];
}

const matchers = [
  {
    name: 'Natwest',
    test: (sortcodeParts: any[]) => {
      if (fullSortcodesMatch(sortcodeParts, [ 60, 83, 71 ])) return false;

      const firstPair = sortcodeParts[ 0 ];

      return firstPair === 1 || between(firstPair, 50, 59) || between(firstPair, 60, 66);
    },
  },
  {
    name: 'Bank of Ireland',
    test: (sortcodeParts: any) => {
      return anyMatch(sortcodeParts[ 0 ], 90, 94);
    },
  },
  {
    name: 'Allied Irish Banks',
    test: (sortcodeParts: any) => {

      return sortcodeParts[ 0 ] === 93;
    },
  },
  {
    name: 'Monzo',
    test: (sortcodeParts: any) => {

      return (
          fullSortcodesMatch(sortcodeParts, [ 4, 0, 4 ]) || fullSortcodesMatch(sortcodeParts, [ 4, 0, 5 ])
      );
    },
  },
  {
    name: 'N26',
    test: (sortcodeParts: any) => {

      return fullSortcodesMatch(sortcodeParts, [ 4, 0, 26 ]);
    },
  },
  {
    name: 'Modulr Finance',
    test: (sortcodeParts: any) => {

      return fullSortcodesMatch(sortcodeParts, [ 4, 0, 72 ]);
    },
  },
  {
    name: 'Revolut LTD',
    test: (sortcodeParts: any) => {

      return fullSortcodesMatch(sortcodeParts, [ 4, 0, 75 ]);
    },
  },
  {
    name: 'OnePay',
    test: (sortcodeParts: any) => {

      return fullSortcodesMatch(sortcodeParts, [ 4, 0, 83 ]);
    },
  },
  {
    name: 'Bo',
    test: (sortcodeParts: any) => {

      return fullSortcodesMatch(sortcodeParts, [ 4, 3, 30 ]);
    },
  },
  {
    name: 'ClearBank',
    test: (sortcodeParts: any) => {

      return fullSortcodesMatch(sortcodeParts, [ 4, 4, 5 ]);
    },
  },
  {
    name: 'Nationwide Building Society',
    test: (sortcodeParts: any) => {

      return sortcodeParts[ 0 ] === 7 && between(sortcodeParts[ 1 ], 0, 49);
    },
  },
  {
    name: 'The Co-operative Bank',
    test: (sortcodeParts: any) => {

      const firstPair = sortcodeParts[ 0 ];
      const secondedPair = sortcodeParts[ 1 ];

      if (firstPair !== 8) return false;

      if (between(secondedPair, 30, 39)) {
        return false;
      }

      return true;
    },
  },
  {
    name: 'Citibank NA',
    test: (sortcodeParts: any) => {

      const firstPair = sortcodeParts[ 0 ];
      const secondedPair = sortcodeParts[ 1 ];

      if (firstPair !== 8) return false;

      return between(secondedPair, 30, 39);
    },
  },
  {
    name: 'Bank of England',
    test: (sortcodeParts: any) => {

      if (sortcodeParts[ 0 ] !== 10) return false;

      return between(sortcodeParts[ 1 ], 0, 79);
    },
  },
  {
    name: 'Halifax Bank of Scotland',
    test: (sortcodeParts: any) => {

      return sortcodeParts[ 0 ] === 11;
    },
  },
  {
    name: 'Sainsbury\'s Bank',
    test: (sortcodeParts: any) => {

      return sortcodeParts[ 0 ] === 12 && between(sortcodeParts[ 1 ], 0, 69);
    },
  },
  {
    name: 'Child & Co',
    test: (sortcodeParts: any) => {

      return sortcodeParts[ 0 ] === 15 && sortcodeParts[ 1 ] === 80;
    },
  },
  {
    name: 'C. Hoare & Co',
    test: (sortcodeParts: any) => {

      return sortcodeParts[ 0 ] === 15 && between(sortcodeParts[ 1 ], 98, 99);
    },
  },
  {
    name: 'Barclays',
    test: (sortcodeParts: any) => {

      if (
          anyFullSortcodesMatch(
              sortcodeParts,
              [ 23, 5, 80 ],
              [ 23, 14, 70 ],
              [ 23, 14, 70 ],
              [ 23, 32, 72 ],
              [ 23, 69, 72 ],
              [ 23, 22, 21 ],
          )
      ) {
        return false;
      }

      const firstPair = sortcodeParts[ 0 ];

      if (anyMatch(firstPair, 13, 14)) {
        return true;
      }

      return between(firstPair, 20, 29);
    },
  },
  {
    name: 'Drummonds Bank',
    test: (sortcodeParts: any) => {

      return fullSortcodesMatch(sortcodeParts, [ 16, 0, 38 ]);
    },
  },
  {
    name: 'Cumberland Building Society',
    test: (sortcodeParts: any) => {

      return fullSortcodesMatch(sortcodeParts, [ 16, 52, 21 ]);
    },
  },
  {
    name: 'Cater Allen',
    test: (sortcodeParts: any) => {

      return fullSortcodesMatch(sortcodeParts, [ 16, 57, 10 ]);
    },
  },
  {
    name: 'Williams & Glyms',
    test: (sortcodeParts: any) => {

      return sortcodeParts[ 0 ] === 17;
    },
  },
  {
    name: 'Coutts & Co',
    test: (sortcodeParts: any) => {

      return sortcodeParts[ 0 ] === 18;
    },
  },
  {
    name: 'Metro Bank',
    test: (sortcodeParts: any) => {

      return fullSortcodesMatch(sortcodeParts, [ 23, 5, 80 ]);
    },
  },
  {
    name: 'TransferWise',
    test: (sortcodeParts: any) => {

      return fullSortcodesMatch(sortcodeParts, [ 23, 14, 70 ]);
    },
  },
  {
    name: 'Fire Financial Services',
    test: (sortcodeParts: any) => {

      return fullSortcodesMatch(sortcodeParts, [ 23, 22, 21 ]);
    },
  },
  {
    name: 'Pockit',
    test: (sortcodeParts: any) => {

      return fullSortcodesMatch(sortcodeParts, [ 23, 32, 72 ]);
    },
  },
  {
    name: 'Prepay Technologies',
    test: (sortcodeParts: any) => {

      return fullSortcodesMatch(sortcodeParts, [ 23, 69, 72 ]);
    },
  },
  {
    name: 'Lloyds Bank and TSB',
    test: (sortcodeParts: any) => {

      if (anyFullSortcodesMatch(sortcodeParts, [ 30, 0, 66 ], [ 30, 2, 48 ])) {
        return false;
      }

      const firstPair = sortcodeParts[ 0 ];
      const secondPair = sortcodeParts[ 1 ];

      if (between(firstPair, 30, 39)) return true;

      if (firstPair === 87) return true;

      if (firstPair === 77 && (between(secondPair, 0, 44) || between(secondPair, 46, 99))) {
        return true;
      }

      return false;
    },
  },
  {
    name: 'Arbuthnot Latham',
    test: (sortcodeParts: any) => {

      return fullSortcodesMatch(sortcodeParts, [ 30, 0, 66 ]);
    },
  },
  {
    name: 'FinecoBank UK',
    test: (sortcodeParts: any) => {

      return fullSortcodesMatch(sortcodeParts, [ 30, 3, 48 ]);
    },
  },
  {
    name: 'HSBC',
    test: (sortcodeParts: any) => {

      if (
          anyFullSortcodesMatch(
              sortcodeParts,
              [ 40, 51, 98 ],
              [ 40, 60, 80 ],
              [ 40, 63, 1 ],
              [ 40, 63, 77 ],
              [ 40, 64, 25 ],
              [ 40, 65, 0 ],
          )
      ) {
        return false;
      }

      const firstPair = sortcodeParts[ 0 ];
      const secondPair = sortcodeParts[ 1 ];
      const thirdPair = sortcodeParts[ 2 ];

      if (firstPair === 49 && secondPair === 99 && between(thirdPair, 79, 99)) {
        return false;
      }

      if (firstPair === 40 && secondPair === 12 && between(thirdPair, 50, 55)) {
        return false;
      }

      return between(firstPair, 40, 49);
    },
  },
  {
    name: 'Deutsche Bank',
    test: (sortcodeParts: any) => {

      const firstPair = sortcodeParts[ 0 ];
      const secondPair = sortcodeParts[ 1 ];
      const thirdPair = sortcodeParts[ 2 ];

      return firstPair === 49 && secondPair === 99 && between(thirdPair, 79, 99);
    },
  },
  {
    name: 'M&S Bank',
    test: (sortcodeParts: any) => {

      const firstPair = sortcodeParts[ 0 ];
      const secondPair = sortcodeParts[ 1 ];
      const thirdPair = sortcodeParts[ 2 ];

      return firstPair === 40 && secondPair === 12 && between(thirdPair, 50, 55);
    },
  },
  {
    name: 'Turkish Bank UK',
    test: (sortcodeParts: any) => {

      return fullSortcodesMatch(sortcodeParts, [ 40, 51, 98 ]);
    },
  },
  {
    name: 'CashFlows',
    test: (sortcodeParts: any) => {

      return fullSortcodesMatch(sortcodeParts, [ 40, 60, 80 ]);
    },
  },
  {
    name: 'The Coventry Building Society',
    test: (sortcodeParts: any) => {

      return fullSortcodesMatch(sortcodeParts, [ 40, 63, 1 ]);
    },
  },
  {
    name: 'Bank of Cyprus UK',
    test: (sortcodeParts: any) => {

      return fullSortcodesMatch(sortcodeParts, [ 40, 63, 77 ]);
    },
  },
  {
    name: 'Virgin Money PLC',
    test: (sortcodeParts: any) => {

      return fullSortcodesMatch(sortcodeParts, [ 40, 64, 25 ]);
    },
  },
  {
    name: 'Norwich & Peterborough Building Society',
    test: (sortcodeParts: any) => {

      return fullSortcodesMatch(sortcodeParts, [ 40, 65, 0 ]);
    },
  },
  {
    name: 'Atom Bank',
    test: (sortcodeParts: any) => {

      return fullSortcodesMatch(sortcodeParts, [ 60, 83, 12 ]);
    },
  },
  {
    name: 'Fidor Bank UK',
    test: (sortcodeParts: any) => {

      return fullSortcodesMatch(sortcodeParts, [ 60, 83, 66 ]);
    },
  },
  {
    name: 'Starling Bank',
    test: (sortcodeParts: any) => {

      return (
          fullSortcodesMatch(sortcodeParts, [ 60, 83, 71 ]) ||
          fullSortcodesMatch(sortcodeParts, [ 4, 0, 40 ])
      );
    },
  },
  {
    name: 'Misc Intenational',
    test: (sortcodeParts: any) => {

      return sortcodeParts[ 0 ] === 70;
    },
  },
  {
    name: 'Bank of England',
    test: (sortcodeParts: any) => {

      return sortcodeParts[ 0 ] === 71;
    },
  },
  {
    name: 'Santander',
    test: (sortcodeParts: any) => {

      if (sortcodeParts[ 0 ] === 72) return true;

      if (sortcodeParts[ 0 ] === 9 && between(sortcodeParts[ 1 ], 0, 19)) {
        return true;
      }

      return sortcodeParts[ 0 ] === 89 && between(sortcodeParts[ 1 ], 0, 29);
    },
  },
  {
    name: 'Bank of Scotland',
    test: (sortcodeParts: any) => {

      return between(sortcodeParts[ 0 ], 80, 81);
    },
  },
  {
    name: 'Clydesdale Bank',
    test: (sortcodeParts: any) => {

      return sortcodeParts[ 0 ] === 82 || sortcodeParts[ 0 ] === 5;
    },
  },
  {
    name: 'RBS',
    test: (sortcodeParts: any) => {

      if (sortcodeParts[ 0 ] === 15) {
        if (sortcodeParts[ 1 ] === 80) return false;
        if (between(sortcodeParts[ 1 ], 98, 99)) return false;
      }

      if (fullSortcodesMatch(sortcodeParts, [ 16, 52, 21 ])) return false;

      return anyMatch(sortcodeParts[ 0 ], 15, 16, 83, 84, 86);
    },
  },
  {
    name: 'Ulsterbank',
    test: (sortcodeParts: any) => {

      return sortcodeParts[ 0 ] === 98;
    },
  },
  {
    name: 'Danske',
    test: (sortcodeParts: any) => {

      return anyMatch(sortcodeParts[ 0 ], 91, 95);
    },
  },
];

export const sortcodeToBank = (sortcode: any) => {
  const sortcodeParts = sortcodeToInts(sortcode.toString());
  for (let i = 0; i < matchers.length; i += 1) {
    const {name, test} = matchers[ i ];

    if (test(sortcodeParts)) {
      return name;
    }
  }
  return 'No match';
};