import React from 'react';
import Button from '../Button/Button';
import loader from '../../assets/loader.gif';
import './BtnGroup.scss';

type BtnGroupPropsType = {
  isActive?: boolean
  isLoading?: boolean
  isSuccess?: boolean
  successMsg?: string
  onCancel: () => void
  onClick: () => void
  btnType?: 'button' | 'submit' | 'reset' | undefined
  className?: string
  activeBtnName?: string
}

const BtnGroup = ({
  isActive = true,
  isLoading = false,
  isSuccess = false,
  successMsg = 'Saved',
  onCancel,
  btnType,
  onClick,
  className,
  activeBtnName = 'Save',
}: BtnGroupPropsType) => {
  return (
      <div className={`btn-group ${className}`}>
        <Button onClick={onCancel} disabled={!isActive}>Cancel</Button>
        {isLoading
            ? <div className="btn-group__loader">
              <img src={loader} alt="loader" />
            </div>
            : isSuccess
                ? <div className={'btn-group__success'}>
                  {successMsg}
                </div>
                : <Button
                    type={btnType}
                    onClick={onClick}
                    disabled={!isActive}>{activeBtnName}</Button>
        }
      </div>
  );
};

export default BtnGroup;