import React, { useEffect, useState } from 'react';
import Chevron from '../ChevronBar/Chevron';
import LineChart from './Chart';
import { ScriptableContext } from 'chart.js';
import './style.scss';
import Title from '../Title/Title';
import FakeDropdown from '../FakeDropdown/FakeDropdown';
import { DateType } from '../CustomersTableContainer/CustomersTableContainer';
import { clearReport, getJourneyData } from '../../app/features/reports/reportsSlice';
import { format } from 'date-fns';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Modal from '../Modal/Modal';
import DatePickerInput from '../DatePickerInput/DatePickerInput';
import { RootState } from '../../app/store';
import { names } from './constants';
import { objectToArray } from '../../helpers/getValuesFromObject';
import { externalTooltipHandler } from './tooltipSettings';

const JourneyChart = () => {
  const today = new Date();
  const dispatch = useAppDispatch();

  const [ isOpen, setIsOpen ] = useState(false);

  const [ date, selectedDate ] = useState<DateType>({
    rangeName: 'Today',
    from: today,
    to: today,
  });

  const {journey} = useAppSelector((state: RootState) => state.reports);

  const total = journey.brokerIntro

  useEffect(() => {
    dispatch(getJourneyData({
      startDate: format(date.from, 'y/MM/dd'),
      endDate: format(date.to, 'y/MM/dd'),
    }));
    return () => {
      dispatch(clearReport());
    };
  }, [ date ]);

  const dataValues: number[] = objectToArray(journey).map((value: unknown) => Number(value) || 0);

  const totalAbandonmentPercentage = dataValues.reduce((acc: number, elem: number) => {
    return acc + elem;
  }, 0);

  const data = {
    labels: [ ...names ],
    datasets: [
      {
        label: '',
        data: [  ...dataValues ],
        fill: {
          target: 'origin',
        },
        backgroundColor: (context: ScriptableContext<'line'>) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(1300, 100, 0, 100);
          gradient.addColorStop(0, 'rgba(39, 14, 191, 0.75)');
          gradient.addColorStop(0.74, 'rgba(0, 188, 212, 0.75)');
          gradient.addColorStop(1, 'rgba(65, 221, 254, 0.75)');
          return gradient;
        },
        tension: .4,
      },
    ],
  };

  const options = {
    pointBackgroundColor: 'black',
    pointRadius: 4,
    responsive: true,
    aspectRatio: 3,
    plugins: {
      tooltip: {
        enabled: false,
        position: 'nearest',
        external: externalTooltipHandler,
        callbacks: {
          title: () => {return '';},
          label: (data: any) => {
            const value = dataValues[ data.dataIndex]
            const abandoned = (value - (dataValues[data.dataIndex + 1] || dataValues[data.dataIndex])) / total || 0
            return `Abandonment: ${(abandoned * 100).toFixed(2) || 0}%`
          },
          afterLabel: (data: any) => {return `Funnel: ${data.raw}`;},
        },
      },
      legend: false,
      title: {
        display: true,
      },
    },
    scales: {
      y: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        beginAtZero: true,
        stacked: false,
        suggestedMax: Math.ceil(Math.max(...dataValues) / 10) * 10,
        ticks: {
          max: Math.ceil(Math.max(...dataValues) / 10) * 10,
          min: 0,
          stepSize: Math.ceil(Math.max(...dataValues) / 10),
          callback: (value: any) => value.toLocaleString(),
        },
        grid: {
          display: true,
          borderDash: [ 5, 5 ],
        },
      },
      x: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          minRotation: 50,
          maxRotation: 50,
        },
      },
    },
  };

  return (
      <div>
        <div className="header">
          <Title text={'Journey map.'} />
          <FakeDropdown label={'Date'} onClick={() => setIsOpen(!isOpen)}>
            {date.rangeName}
          </FakeDropdown>
        </div>
        <div className="line">
          <h3 className="sub-title">Phases</h3>
          <Chevron />
        </div>
        <div className="line">
          <h3 className="sub-title">Abandonment</h3>
          <div className="abandonment">
            {dataValues.map((elem, idx) => {
              const abandoned = (elem - (dataValues[idx + 1] || dataValues[idx])) / total || 0
              return (
                  <div key={idx} className={'abandonment__item'}>
                    {(abandoned * 100).toFixed(2) || 0}%
                  </div>
              );
            })}
          </div>
        </div>
        <div>
          <h3 className="sub-title">Funnel</h3>
          <LineChart data={data} options={options} />
        </div>
        <Modal show={isOpen} onClose={() => setIsOpen(false)}>
          <DatePickerInput onClose={() => setIsOpen(false)} date={date} handleSelectedDates={selectedDate} />
        </Modal>
      </div>
  );
};
export default JourneyChart;