import React, { useEffect, useState } from 'react';
import ChainedModal from '../../ChainedModal/ChainedModal';
import DialogPopupWrapper from '../../DialogPopup/DialogPopup';
import Button from '../../Button/Button';
import '../CommsModal.scss';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { ISelectOption } from '../../CustomSelect/CustomSelect';
import { useOutletContext } from 'react-router-dom';
import { RootState } from '../../../app/store';
import { sendSms } from '../../../app/features/comms/commsSlice';
import { getAllTemplates, SmsTemplateType } from '../../../app/features/templates/templatesSlice';
import { formatToSelect } from '../../../utils/formatToSelect';
import SmsContainer from '../../Comms/SmsContainer';

const Modal1 = (props: any) => {

  const [ customer ] = useOutletContext() as any;
  const editorRef: React.MutableRefObject<any | null> = React.useRef(null);

  const dispatch = useAppDispatch();
  const {sms} = useAppSelector((state: RootState) => state.templates);
  const [ templates, setTemplates ] = useState<any[]>([]);
  const [ content, setContent ] = React.useState('');
  const [ contentTouched, setContentTouched ] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState<ISelectOption | null>(null)

  useEffect(() => {
    if (!sms.length) {
      dispatch(getAllTemplates());
    }
  }, []);

  useEffect(() => {
    setTemplates(formatToSelect(sms));
  }, [  sms ]);


  const handleSelectedTemplate = (obj: ISelectOption) => {
    setSelectedOption(obj)
    const selectedTemplate = JSON.parse(JSON.stringify(sms.find((t): boolean => t.id === obj.value) as SmsTemplateType));
    //replace variable with customer firstName
    selectedTemplate.text = selectedTemplate.text.replace(
      '${customer.fullName}', customer.fullName.split(' ')[ 0 ]);
    editorRef.current.clearContent();
    editorRef.current.insertContent(selectedTemplate.text);
  };





  const handleSend = () => {
    dispatch(sendSms({message: content}));
    props.showNext()
  };

  const handleCancel = () => {
    props.closeChainedModal()
  }

  const handleContentChanges = (content: string) => {
    setContent(content as any);
    setContentTouched(true);
  };


  return (
      <div className="comms-modal">
        <SmsContainer
            options={templates}
            setSelectedOption={handleSelectedTemplate}
            handleCancel={handleCancel}
            handleSend={handleSend}
            contentTouched={contentTouched}
            content={content}
            editorRef={editorRef}
            setContent={handleContentChanges}
            selectedOption={selectedOption}
        />
      </div>
  );
};

const Modal2 = (props: any) => {

  return (
      <DialogPopupWrapper title={'SMS'} className={'modal'}>
        <div className="modal__body">
          <p className="modal__text">Message sent.</p>
          <Button onClick={() => props.closeChainedModal()} className={'modal__btn'}>OK</Button>
        </div>
      </DialogPopupWrapper>
  );
};

const SmsChainModal = ({onClose, visible}: any) => {

  return <ChainedModal
      visible={visible}
      onRequestClose={onClose}
      closeOnBackground
      elements={[
        {
          component: Modal1,
        },
        {
          component: Modal2,
        },
      ]}
  />;
};

export default SmsChainModal;