import { ChangeEventHandler, useState } from 'react';
import { DayPicker, DateFormatter, DateRange, SelectRangeEventHandler } from 'react-day-picker';
import { format, isAfter, isBefore, isValid, parse, subMonths } from 'date-fns';
import Input from '../input/Input';
import Index from '../Button/Button';
import 'react-day-picker/dist/style.css';
import './DatePickerInput.scss';
import subDays from 'date-fns/subDays';
import arrow from '../../assets/arrow-right.png';

const today = new Date();

const ranges = [
  {
    name: 'Today',
    range: {
      from: today,
      to: today,
    },
  },
  {
    name: 'Last 7 days',
    range: {
      from: subDays(today, 7),
      to: today,
    },
  },
  {
    name: 'Last 14 days',
    range: {
      from: subDays(today, 14),
      to: today,
    },
  },
  {
    name: 'Last 30 days',
    range: {
      from: subDays(today, 30),
      to: today,
    },
  },
  {
    name: 'Last 3 months',
    range: {
      from: subMonths(today, 3),
      to: today,
    },
  },
  {
    name: 'Last 12 months',
    range: {
      from: subMonths(today, 12),
      to: today,
    },
  },
  {
    name: 'Month to date',
    range: {
      from: parse('01/' + format(today, 'MM/y'), 'dd/MM/y', today),
      to: today,
    },
  },
  {
    name: 'Quarter to date',
    range: {
      from: parse(format(today, 'Q'), 'Q', today),
      to: today,
    },
  },
  {
    name: 'All time',
    range: {
      from: parse('01/01/2021', 'dd/LL/y', today),
      to: today,
    },
  },
  {
    name: 'Custom',
    range: {
      from: today,
      to: today,
    },
  },
];

const formatCaption: DateFormatter = (month, options) => {
  return <>{format(month, 'LLLL', {locale: options?.locale})}</>;
};

const DatePicker = ({onClose, handleSelectedDates, date}: any) => {

  const [ rangeName, setRangeName ] = useState(date && date.rangeName || ranges[ 0 ].name);
  const [ selectedRange, setSelectedRange ] = useState<DateRange>(
      date && date.from ? {to: date.to, from: date.from} : ranges[ 0 ].range);
  const [ fromValue, setFromValue ] = useState<string>(date && format(date.from, 'dd/MM/y') || format(today, 'dd/MM/y'));
  const [ toValue, setToValue ] = useState<string>(date && format(date.to, 'dd/MM/y') || format(today, 'dd/MM/y'));

  const handleFromChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setFromValue(e.target.value);
    const date = parse(e.target.value, 'dd/MM/y', today);
    if (selectedRange?.to && isAfter(date, selectedRange.to)) {
      setSelectedRange({from: selectedRange.to, to: date});
    } else {
      setSelectedRange({from: date, to: selectedRange?.to});
    }
  };
  const handleToChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setToValue(e.target.value);
    const date = parse(e.target.value, 'dd/MM/y', today);

    if (!isValid(date)) {
      return setSelectedRange({from: selectedRange?.from, to: undefined});
    }
    if (selectedRange?.from && isBefore(date, selectedRange.from)) {
      setSelectedRange({from: date, to: selectedRange.from});
    } else {
      setSelectedRange({from: selectedRange?.from, to: date});
    }
  };
  const handleRangeSelect: SelectRangeEventHandler = (range: DateRange | any) => {
    setSelectedRange(range);
    if (range?.from) {
      setFromValue(format(range.from, 'dd/MM/y'));
    } else {
      setFromValue('');
    }
    if (range?.to) {
      setToValue(format(range.to, 'dd/MM/y'));
    } else {
      setToValue('');
    }
  };

  return (
      <div className="date-picker">
        <ul className="ranges">
          {ranges.map((range) => (
              <li className={range.name === rangeName ? 'active' : ''} key={range.name}>
                <button
                    onClick={(e) => {
                      handleRangeSelect(range.range, today, null as any, e);
                      setRangeName(range.name);
                    }}>
                  {range.name}
                </button>
              </li>
          ))}
        </ul>
        <div className="main-container">
          <DayPicker
              weekStartsOn={1}
              numberOfMonths={2}
              formatters={{formatCaption}}
              mode="range"
              selected={selectedRange}
              onSelect={handleRangeSelect}
              onDayClick={() => setRangeName('Custom')}
          />
          <div className="bottom-block">
            <div className="input-block">
              <div className="input-wrapper">
                <Input value={fromValue} onChange={handleFromChange} />
              </div>
              <div className="arrow">
                <img src={arrow} alt="" />
              </div>
              <div className="input-wrapper">
                <Input value={toValue} onChange={handleToChange} />
              </div>
            </div>
            <div className="btn-block">
              <Index onClick={onClose}>Cancel</Index>
              <Index
                  onClick={() => {
                    onClose();
                    handleSelectedDates({
                      rangeName,
                      from: selectedRange.from,
                      to: selectedRange.to,
                    });
                  }}>
                Set date
              </Index>
            </div>
          </div>
        </div>
      </div>
  );
};

export default DatePicker;