import React, { useEffect } from 'react';
import { Field, Form, Formik, FormikValues } from 'formik';
import Input from '../../components/input/Input';
import './Profile.scss';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { updateUser, setSuccess } from '../../app/features/user/userSlice';
import Title from '../../components/Title/Title';
import BtnGroup from '../../components/BtnGroup/BtnGroup';
import { getChangedValues } from '../../utils/getChangedValues';
import Editor from '../../components/TextEditor/TextEditor';
import { getCharsCount } from '../../utils/getEditorCharsCount';

const schema = Yup.object().shape({
  fullName: Yup.string().min(2, 'Too short!').required(),
  email: Yup.string().email('Invalid email').required(),
  currentPassword: Yup.string().when('password', (value, schema) => {
    if (!value) {
      return schema.notRequired();
    }
    return Yup.string().min(1, 'Please enter your current password!').required();
  }),
  password: Yup.string().
      matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, {
        message: 'Must contain at least 1 uppercase letter, 1 lowercase letter, and 1 number and least 8 characters',
        excludeEmptyString: true,
      }),
  confirmPassword: Yup.string().when('password', (value, schema) => {
    if (!value) {
      return schema.notRequired();
    }
    return schema.oneOf([ Yup.ref('password') ], 'Please repeat the password').required('Required');
  }),
});

const ProfilePage = () => {

  const {isLoading, isSuccess, user} = useAppSelector((state) => state.user);

  const nonEditableText = `<br><hr><span style="color: #999999; font-size: 14px;"><p> Ratio Two Limited (10688741) trading as Familio is registered at Telephone House, 18 Christchurch Road, Bournemouth, BH1 3NE. This communication and any attachments may contain personal data. If you require information about how and why we use personal data or have any queries regarding personal data, please view our privacy policy. If you are not the intended recipient of this email, you must not disclose, copy or show this email and any attachment to anyone nor may you rely on them for any purpose. If you have received this email in error please notify the sender immediately and then delete it from your system. </p>`;

  const dispatch = useAppDispatch();

  const [ signature, setSignature ] = React.useState(user.signature);
  const [ signatureTouched, setSignatureTouched ] = React.useState(false);

  const editorRef = React.useRef(null);

  useEffect(() => {
    const signatureChars = getCharsCount(user.signature);
    if (!signatureChars) {
      setSignature(nonEditableText);
      //@TODO temp fix, replace with https://github.com/theKashey/use-callback-ref
      setTimeout(() => {
        // @ts-ignore
        editorRef.current.insertContent(nonEditableText);
      }, 1);
    }
  }, [ user.signature ]);

  const initialValues = {
    fullName: user.fullName,
    email: user.email,
    currentPassword: '',
    password: '',
    confirmPassword: '',
  };
  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        dispatch(setSuccess(false));
      }, 5000);
    }
  }, [ isSuccess ]);

  return (
      <div className="profile">
        <Title text={'Profile.'} />
        <div className="profile__form form">
          <Formik
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={() => {}}>
            {(
                {
                  values,
                  dirty,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  errors,
                  touched,
                  resetForm,
                }) =>
                (
                    <Form>
                      <form className="form__input-block">
                        <h2 className="form__title">
                          Your details
                        </h2>
                        <Field
                            label="Name"
                            name="fullName"
                            as={Input}
                            value={values.fullName}
                            error={touched.fullName && errors.fullName}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                        />
                        <Field
                            label="Email"
                            name="email"
                            type="email"
                            as={Input}
                            value={values.email}
                            error={touched.email && errors.email}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                        />
                      </form>
                      <div className="form__input-block">
                        <h2 className="form__title">
                          Your password
                        </h2>
                        <Field
                            label="Old password"
                            name="currentPassword"
                            type="password"
                            validateOnBLur
                            placeholder="Type old password"
                            as={Input}
                            error={touched.currentPassword && errors.currentPassword}
                            value={values.currentPassword}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                        />
                        <Field
                            label="New password"
                            name="password"
                            type="password"
                            placeholder="Type new password"
                            as={Input}
                            error={touched.password && errors.password}
                            value={values.password}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                        />
                        <Field
                            label="Repeat new password"
                            name="confirmPassword"
                            type="password"
                            placeholder="Repeat new password"
                            as={Input}
                            error={touched.confirmPassword && errors.confirmPassword}
                            value={values.confirmPassword}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur} />
                      </div>
                      <div className="form__textarea-block">
                        <h2 className="form__title">
                          Signature
                        </h2>
                        <Editor
                            className={'form__editor'}
                            name="signature"
                            content={signature}
                            output={'json'}
                            ref={editorRef}
                            onChange={(json: any) => {
                              setSignature(json);
                              setSignatureTouched(true);
                            }}
                        />
                      </div>
                      <div className="form__buttons">
                        <BtnGroup
                            onClick={() => {
                              const updatedFields = getChangedValues(values, initialValues);
                              dispatch(updateUser({...updatedFields, signature}));
                            }}
                            isActive={dirty || isSubmitting || signatureTouched}
                            onCancel={() => {
                              resetForm({values: {...initialValues}});
                              // @ts-ignore
                              editorRef.current.clearContent();
                              // @ts-ignore
                              editorRef.current.insertContent(initialValues.signature);
                            }}
                            isLoading={isLoading}
                            isSuccess={isSuccess}
                        />
                      </div>
                    </Form>
                )}
          </Formik>
        </div>
      </div>
  );
};

export default ProfilePage;