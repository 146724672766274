import React, { useEffect, useState } from 'react';
import FakeDropdown from '../../components/FakeDropdown/FakeDropdown';
import Card from '../../components/Card/Card';
import './ManagementInformation.scss';
import LineChart from '../../components/LineChart/LineChart';
import Loader from '../../components/Loader/Loader';
import Modal from '../../components/Modal/Modal';
import DatePickerInput from '../../components/DatePickerInput/DatePickerInput';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { clearReport, getChartData, SingleChartReportType } from '../../app/features/reports/reportsSlice';
import { format } from 'date-fns';
import { formatMoney } from '../../utils/formatMoney';
import Title from '../../components/Title/Title';
import { ScriptableContext } from 'chart.js';
import CsvDownload from 'react-json-to-csv';

type StatsType = {
  leads: number,
  applications: number,
  successes: number,
  declines: number,
  paid: number,
  paidAmount: number,
}
type DateType = {
  rangeName: string,
  from: Date,
  to: Date
}

const datasetsTemplate = [
  {
    label: 'Leads',
    id: 'leads',
    data: [],
    tension: 0.2,
    fill: 'start',
    pointBackgroundColor: '#fff',
    pointBorderColor: '#290AE9FF',
    backgroundColor: (context: ScriptableContext<'line'>) => {
      const ctx = context.chart.ctx;
      const gradient = ctx.createLinearGradient(295, 0, 305, 300);
      gradient.addColorStop(0, 'rgba(41, 10, 233, 1)');
      gradient.addColorStop(0, 'rgba(41, 10, 233, 0.15)');
      gradient.addColorStop(1, 'rgba(255, 255, 255, 1)');
      return gradient;
    },
  },
  {
    label: 'Applications',
    id: 'applications',
    data: [],
    tension: 0.2,
    fill: 'start',
    pointBackgroundColor: '#fff',
    pointBorderColor: '#32D72EFF',
    backgroundColor: (context: ScriptableContext<'line'>) => {
      const ctx = context.chart.ctx;
      const gradient = ctx.createLinearGradient(295, 0, 305, 300);
      gradient.addColorStop(0, 'rgba(50, 215, 46, 1)');
      gradient.addColorStop(0, 'rgba(50, 215, 46, 0.25)');
      gradient.addColorStop(1, 'rgba(255, 255, 255, 1)');
      return gradient;
    },
  },
  {
    label: 'Successes',
    id: 'successes',
    data: [],
    tension: 0.2,
    fill: 'start',
    pointBackgroundColor: '#fff',
    pointBorderColor: '#41DDFE',
    backgroundColor: (context: ScriptableContext<'line'>) => {
      const ctx = context.chart.ctx;
      const gradient = ctx.createLinearGradient(295, 0, 305, 300);
      gradient.addColorStop(0, 'rgba(65, 221, 254, 1)');
      gradient.addColorStop(0, 'rgba(65, 221, 254, 0.15)');
      gradient.addColorStop(1, 'rgba(255, 255, 255, 1)');
      return gradient;
    }
  },
  {
    label: 'Declines',
    id: 'declines',
    data: [],
    tension: 0.2,
    fill: 'start',
    pointBackgroundColor: '#fff',
    pointBorderColor: '#FF0000',
    backgroundColor: (context: ScriptableContext<'line'>) => {
      const ctx = context.chart.ctx;
      const gradient = ctx.createLinearGradient(295, 0, 305, 300);
      gradient.addColorStop(0, 'rgb(255,0,0, 1)');
      gradient.addColorStop(0, 'rgba(255,0,0, 0.15)');
      gradient.addColorStop(1, 'rgba(255,0,0, 1)');
      return gradient;
    }
  },
  {
    label: 'Paid out (£)',
    id: 'paidAmount',
    data: [],
    tension: 0.2,
    yAxisID: 'y1',
    fill: 'start',
    pointBackgroundColor: '#fff',
    pointBorderColor: '#FC9C38FF',
    backgroundColor: (context: ScriptableContext<'line'>) => {
      const ctx = context.chart.ctx;
      const gradient = ctx.createLinearGradient(295, 0, 305, 300);
      gradient.addColorStop(0, 'rgba(252, 156, 56, 1)');
      gradient.addColorStop(0, 'rgba(252, 156, 56, 0.5)');
      gradient.addColorStop(1, 'rgba(255, 255, 255, 1)');
      return gradient;
    },
  },
  {
    label: 'Paid out',
    id: 'paid',
    data: [],
    tension: 0.2,
    yAxisID: 'y1',
    fill: 'start',
    pointBackgroundColor: '#fff',
    pointBorderColor: '#e90ad6',
    backgroundColor: (context: ScriptableContext<'line'>) => {
      const ctx = context.chart.ctx;
      const gradient = ctx.createLinearGradient(295, 0, 305, 300);
      gradient.addColorStop(0, 'rgba(233, 10, 214, 1)');
      gradient.addColorStop(0, 'rgba(233, 10, 214, 0.15)');
      gradient.addColorStop(1, 'rgba(233, 10, 214, 1)');
      return gradient;
    },
  },
]

const sumData = (cw: any, direct: any) => {
  const newArr: any = [];
  for (let day of [...cw, ...direct]) {
    const idx = newArr.findIndex((i: any) => i.date === day.date);
    if (idx > -1) {
      newArr[ idx ] = {
        date: day.date,
        leads: newArr[ idx ].leads + day.leads,
        applications: newArr[ idx ].applications + day.applications,
        successes: newArr[ idx ].successes + day.successes,
        declines: newArr[ idx ].declines + day.declines,
        paid: newArr[ idx ].paid + day.paid,
        paidAmount: newArr[ idx ].paidAmount + day.paidAmount,
      };
    } else {
      newArr.push(day);
    }
  }
  return newArr;
}

const getTotalCardsValues = (data: SingleChartReportType[] | any) => {
  if (!data.length) {
    return {
      leads: 0,
      applications: 0,
      successes: 0,
      declines: 0,
      paid: 0,
      paidAmount: 0,
    };
  }
  return data.reduce(function(previousValue: any, currentValue: any) {
    return {
      leads: previousValue.leads + currentValue.leads,
      applications: previousValue.applications + currentValue.applications,
      successes: previousValue.successes + currentValue.successes,
      declines: previousValue.declines + currentValue.declines,
      paid: previousValue.paid + currentValue.paid,
      paidAmount: previousValue.paidAmount + currentValue.paidAmount,
    };
  });
};
const getListOfDates = (arr: any) => {
  const dates: string[] = [];
  arr.forEach((elem: any) => {
    dates.push(elem.date);
  });
  return dates;
};

const generateDatasets = (arr: any, dataset: any) => {

  const newDataset = dataset.map((a: any) => {return {...a}})

  newDataset.map((elem: any) => {
    elem.data = []
  })

  arr.forEach((elem: any) => {
    Object.entries(elem).forEach(([ key, value ]) => {
      newDataset.forEach((elem: any) => {
        if (elem.id === key) {
          elem.data.push(value);
          return elem;
        }
      });
    });
  });
  return newDataset;
};

const ManagementCard = ({id, name, value, active, color, onClick}: any) => {
  return (
      <Card color="light" size="sm" className={`management__card card ${value > 0 && active ? color : ''}`}
          {...(value > 0 && {onClick: () => onClick(id)})}>
        <div className="card__inner">
          <span className="card__title">{name}</span>
          <span className="card__number">{id === 'paidAmount' ? formatMoney(value).slice(0, -3) : value}</span>
        </div>
      </Card>
  )
}

const ManagementInformation = () => {
  const today = new Date();
  const dispatch = useAppDispatch();
  const {chart, isLoading} = useAppSelector((state: RootState) => state.reports);

  const [ isOpen, setIsOpen ] = useState(false);
  const [ labels, setLabels ] = useState<any[]>([]);
  const [ allDatasets, setAllDatasets ] = useState([]);
  const [ activeDatasets, setActiveDatasets ] = useState([]);
  const [ allDirect, setAllDirect ] = useState<StatsType>({
    leads: 0,
    applications: 0,
    successes: 0,
    declines: 0,
    paid: 0,
    paidAmount: 0,
  });
  const [ allCW, setAllCW ] = useState<StatsType>({
    leads: 0,
    applications: 0,
    successes: 0,
    declines: 0,
    paid: 0,
    paidAmount: 0,
  });
  const [ date, selectedDate ] = useState<DateType>({
    rangeName: 'Today',
    from: today,
    to: today,
  });
  const [ cards, setCards ] = useState([
    {id: 'leads', name: 'Leads', value: 0, color: 'blue', active: true},
    {id: 'applications', name: 'Applications', value: 0, color: 'green', active: true},
    {id: 'successes', name: 'Successes', value: 0, color: 'aqua', active: true},
    {id: 'declines', name: 'Declines', value: 0, color: 'redBorder', active: true},
    {id: 'paid', name: 'Paid out', value: 0, color: 'pink', active: true},
    {id: 'paidAmount', name: 'Paid out (£)', value: 0, color: 'orange', active: true},
  ]);

  let name = 'Today';
  if (date && date.rangeName) {
    name = date.rangeName;
  }

  const handleUpdatedDatasets = (cards: any, datasets: any) => {
    const active: any = [];
    cards.forEach((elem: any) => {
      if (elem.active) {
        active.push(elem.id);
      }
    });
    return datasets.filter((elem: any) => active.includes(elem.id));
  };

  const handleActive = (id: string) => {
    const updatedCards = cards.map((elem: any) => {
      if (elem.id === id) {
        return {...elem, active: !elem.active};
      }
      return elem;
    });
    setCards(updatedCards);
    setActiveDatasets(handleUpdatedDatasets(updatedCards, allDatasets));
  };

  useEffect(() => {
    dispatch(getChartData({
      startDate: format(date.from, 'y/MM/dd'),
      endDate: format(date.to, 'y/MM/dd'),
    }));
    return () => {
      dispatch(clearReport());
    };
  }, [ date ]);

  useEffect(() => {
    const data = sumData(chart.direct, chart.cw);
    setAllCW(getTotalCardsValues(chart.cw));
    setAllDirect(getTotalCardsValues(chart.direct));
    const totalValues = getTotalCardsValues(data);
    const labels = getListOfDates(data);
    const updatedCards = cards.map(elem => {
      return {...elem, value: totalValues[ elem.id ]};
    });
    setCards(updatedCards);
    setLabels(labels);

    const datasets = generateDatasets(data, datasetsTemplate);

    setAllDatasets(datasets as any);
    setActiveDatasets(datasets as any);
  }, [ chart ]);

  return (
      <div className="management">
        <div className="management__header">
          <Title text={'Management information.'} />
          <FakeDropdown label={'Date'} onClick={() => setIsOpen(!isOpen)}>
            {date.rangeName}
          </FakeDropdown>
        </div>
        <div className="management__cards">
          {
            cards.map((elem: any) => (
                <ManagementCard key={elem.id}
                    id={elem.id}
                    name={elem.name}
                    value={elem.value}
                    active={elem.active}
                    color={elem.color}
                    onClick={handleActive} />
            ))
          }
        </div>
        <div className="management__chart">
          <LineChart labels={labels} datasets={activeDatasets} />
        </div>
        <div className="management__table">
          <table className="table">
            <thead>
            <tr>
              <th>Source</th>
              <th>Leads</th>
              <th>Applications</th>
              <th>Successes</th>
              <th>Declines</th>
              <th>Paid out</th>
              <th>Paid out (£)</th>
            </tr>
            </thead>
            {
              isLoading ? <Loader /> :
                  <tbody>
                  <tr>
                    <td>Choose Wisely</td>
                    <td>{allCW.leads}</td>
                    <td>{allCW.applications}</td>
                    <td>{allCW.successes}</td>
                    <td>{allCW.declines}</td>
                    <td>{allCW.paid}</td>
                    <td>{formatMoney(allCW.paidAmount)}</td>
                  </tr>
                  <tr>
                    <td>Direct</td>
                    <td>{allDirect.leads}</td>
                    <td>{allDirect.applications}</td>
                    <td>{allDirect.successes}</td>
                    <td>{allDirect.declines}</td>
                    <td>{allDirect.paid}</td>
                    <td>{formatMoney(allDirect.paidAmount)}</td>
                  </tr>
                  </tbody>
            }
          </table>
        </div>
        <div className="management__btn-block">
          <CsvDownload className="btn" filename={'managementInformation.csv'} data={sumData(chart.direct, chart.cw)}>Download csv</CsvDownload>
        </div>
        <Modal show={isOpen} onClose={() => setIsOpen(false)}>
          <DatePickerInput onClose={() => setIsOpen(false)} date={date} handleSelectedDates={selectedDate} />
        </Modal>
      </div>
  );
};

export default ManagementInformation;