import React from 'react';
import Menu from '../Menu/Menu';
import './Header.scss';

type HeaderType = {
  title: string
  fullName: string
  setShowModal: (id: number) => void
  agentView: boolean
  handleChangeProfileView:() => void
}

const Header = ({title, fullName, handleChangeProfileView, agentView, setShowModal}: HeaderType) => {
  return (
      <div className="header">
        <h2 className="header__title">
          {title} {fullName}.
        </h2>
        <Menu agentView={agentView} handleChangeProfileView={handleChangeProfileView} setShowModal={setShowModal} />
      </div>
  );
};

export default React.memo(Header);
