import React from 'react';
import CardContainer from '../../CardContainer/CardContainer';
import editIcon from '../../../assets/edit.svg';

interface IProps {
  loanAmount: number | null,
  loanTerm: number | null,
  loanRepay: number | null,
  loanCost: number | null,
  loanTotal: number | null,
  apr: number | null,
  onClick: () => void,
  className: string
}

const LoanInfoCard = ({onClick, loanAmount, loanTerm, loanTotal, loanRepay, loanCost, apr, className}: IProps) => {

  return (
      <CardContainer title={'Loan'} comment={'INFORMATION ABOUT THE LOAN'} className={`card ${className}`} onClick={onClick}>
        <h1 className="card__info">
          £{loanAmount}
          <span className="card__info_small">at</span>
          {apr}%
        </h1>
        <p className="card__text">
          {loanTerm} months
        </p>
        <p className="card__text">
          £{loanRepay} per month
        </p>
        <p className="card__text">
          £{loanTotal} total repayable
        </p>
        <p className="card__text">
          £{loanCost} total charge for credit
        </p>
      </CardContainer>
  );
};

export default LoanInfoCard;
