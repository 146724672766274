import { AuthData } from '../../../pages/LoginPage/LoginPage';
import CustomAxios from '../../CustomAxios/CustomAxios';

export const signInAPI = (data: AuthData) => {
  return CustomAxios.post(`${process.env.REACT_APP_AUTH_URL}/dev/auth/signin`, data );
}
export const signOutAPI = () => {
  return CustomAxios.get(`${process.env.REACT_APP_AUTH_URL}/dev/auth/signout` );
}

export const getMeAPI = () => {
  return CustomAxios.get(`${process.env.REACT_APP_AUTH_URL}/dev/auth/me`);
}