import React from 'react';
import ChainedModal from '../../ChainedModal/ChainedModal';
import DialogPopupWrapper from '../../DialogPopup/DialogPopup';
import BtnGroup from '../../BtnGroup/BtnGroup';
import Button from '../../Button/Button';
import '../DialogModal.scss';
import { useAppDispatch } from '../../../app/hooks';
import { deleteUser } from '../../../app/features/users/usersSlice';

const Modal1 = (props: any) => {

  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(deleteUser(props.id));
    props.showNext();
  };

  return (
      <DialogPopupWrapper title={'Warning'} className={'modal'}>
        <div className="modal__body">
          <p>
            Are you sure you wish to delete this account?
          </p>
          <BtnGroup
              className="modal__btn"
              activeBtnName={'OK'}
              onCancel={() => props.closeChainedModal()}
              onClick={handleClick} />
        </div>
      </DialogPopupWrapper>
  );
};
const Modal2 = (props: any) => {

  return (
      <DialogPopupWrapper title={'Share'} className={'modal'}>
        <div className="modal__body">
          <p className="modal__text">Account deleted.</p>
          <Button onClick={() => props.closeChainedModal()} className={'modal__btn'}>OK</Button>
        </div>
      </DialogPopupWrapper>
  );
};

const WarningAccountModal = ({onClose, visible, id}: any) => {
  return <ChainedModal
      visible={visible}
      onRequestClose={onClose}
      closeOnBackground
      elements={[
        {
          component: Modal1,
          props: {id},
        },
        {
          component: Modal2,
        },
      ]}
  />;
};

export default WarningAccountModal;