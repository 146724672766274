import React from 'react';
import './Card.scss';
import cx from 'classnames';

type CardPropsType = {
  size: 'lg' | 'sm' | 'full'
  color: string,
  onClick?: () => void,
  className?: string,
}

const Card = ({children, size, color, onClick, className}: React.PropsWithChildren<CardPropsType>) => {

  return (
      <div onClick={onClick} className={cx(size, color, className,{
        clickable: !!onClick,
        card: true,
      })}>
        {children}
      </div>
  );
};

export default Card;
