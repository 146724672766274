import React from 'react';

type ItemPropsType = {
  name: string
  value: string | boolean | number
  initialValue: string | boolean | number
  asked: boolean
  reason: string | null
}

const Item = ({name, value, initialValue, reason, asked}: ItemPropsType) => {
  if (name === 'Income change') {
    return (
        <div className={`station ${!asked && 'inactive'}`}>
          <span className="name">{name}</span>
          <div className="circle" />
          <div className="value">
            {<p>{value > 0 ? 'Yes' : 'No'}</p>}
            {asked && initialValue !== value && typeof initialValue === 'number' && <p className='old'>£{initialValue}</p>}
            {reason && <p>{reason}</p>}
            {asked && value > 0 && typeof value === 'number' && <p>£{value}</p>}
            {asked && typeof value === 'string' && <p>{value}</p>}
          </div>
        </div>
    );
  }
  return (
      <div className={`station ${!asked && 'inactive'}`}>
        <span className="name">{name}</span>
        <div className="circle" />
        {asked && typeof value === 'boolean' && <p>{value ? 'Yes' : 'No'}</p>}
        <div className="value">
          {asked && initialValue !== value && typeof initialValue === 'number' && <p className='old'>£{initialValue}</p>}
          {reason && <p>{reason}</p>}
          {asked && typeof value === 'number' && <p>£{value}</p>}
          {asked && typeof value === 'string' && <p>{value}</p>}
        </div>
      </div>
  );
};

export default Item;