import CustomAxios from '../../CustomAxios/CustomAxios';

export const searchCustomersAPI = (searchValue: string) => {
  return CustomAxios.get(`${process.env.REACT_APP_CUSTOMERS_URL}/dev/customer?search=${searchValue}`);
};
export const getCustomerByIdAPI = (id: string) => {
  return CustomAxios.get(`${process.env.REACT_APP_CUSTOMERS_URL}/dev/customer/${id}`);
};
export const updateLoanAPI = (id: string, data: any) => {
  return CustomAxios.patch(`${process.env.REACT_APP_CUSTOMERS_URL}/dev/customer/${id}/loan`, data);
};
export const updateInfoAPI = (id: string, data: any) => {
  return CustomAxios.patch(`${process.env.REACT_APP_CUSTOMERS_URL}/dev/customer/${id}/details`, data);
};
export const fetchAddressesAPI = (postcode: string) => {
  return CustomAxios.get(`${process.env.REACT_APP_CUSTOMERS_URL}/dev/postcode?pc=${postcode}`);
};
export const getEventsAPI = (id: string) => {
  return CustomAxios.get(`${process.env.REACT_APP_CUSTOMERS_URL}/dev/customer/${id}/events`);
};
export const shareAPI = (data: { notifyId: string, id: string }) => {
  return CustomAxios.post(`${process.env.REACT_APP_CUSTOMERS_URL}/dev/customer/share`, data);
};
export const assignAPI = (data: any) => {
  return CustomAxios.post(`${process.env.REACT_APP_CUSTOMERS_URL}/dev/customer/assign`, data);
};