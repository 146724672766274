import React, { useEffect } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { clearToast } from '../../app/features/ui/uiSlice';

const Toast = () => {
  const dispatch = useAppDispatch();

  const {message, open, severity} = useAppSelector((state) => state.ui);

  setTimeout(() => {
    dispatch(clearToast());
  }, 4000);

  useEffect(() => {
    if (open) {
      switch (severity) {
        case 'success':
          toast.success(message);
          break;
        case 'failed':
          toast.error(message);
          break;
      }
    }
  }, [ open ]);

  return <Toaster />;
};

export default Toast;