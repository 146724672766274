import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { addTemplateAPI, getAllTemplatesAPI, deleteTemplateAPI, updateTemplateAPI } from './templatesAPI';
import { showToast } from '../ui/uiSlice';


export type EmailTemplateType = {
  id: number
  name: string
  subject: string
  body: Object
}
export type SmsTemplateType = {
  id: number
  name: string
  text: string
}

type InitialStateType = {
  isLoading: boolean,
  isSuccess: boolean,
  isDeleteInProgress: boolean,
  isDeleteSuccess: boolean,
  isDeleteFinished: boolean,
  emails: EmailTemplateType[],
  sms: SmsTemplateType[],
  error: string | null
}

const initialState: InitialStateType = {
  isLoading: false,
  isSuccess: false,
  isDeleteSuccess: false,
  isDeleteFinished: false,
  isDeleteInProgress: false,
  emails: [],
  sms: [],
  error: null
};

export const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    setDeleteFinished: (state, action: PayloadAction<boolean>) => {
      state.isDeleteFinished = action.payload;
    },
    setIsDeleteSuccess: (state, action: PayloadAction<boolean>) => {
      state.isDeleteSuccess = action.payload;
    },
    setSuccess: (state, action: PayloadAction<boolean>) => {
      state.isSuccess = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAllTemplates.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllTemplates.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.emails = payload.email;
      state.sms = payload.sms;
    });
    builder.addCase(getAllTemplates.rejected, (state, { payload }) => {
      state.isLoading = false;
    });

    builder.addCase(addTemplate.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addTemplate.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (payload.type === 'email') {
        state['emails'].push(payload.item);
      }
      if (payload.type === 'sms') {
        state['sms'].push(payload.item);
      }
      state.isSuccess = true;
    });
    builder.addCase(addTemplate.rejected, (state, { payload }) => {
      state.isLoading = false;
    });

    builder.addCase(deleteTemplate.pending, (state) => {
      state.isDeleteInProgress = true;
      state.isDeleteSuccess = false;
      state.isDeleteFinished = false;
    });
    builder.addCase(deleteTemplate.fulfilled, (state, { payload }) => {
      state.isDeleteInProgress = false;
      if (payload.type === 'email') {
        state['emails'] = state['emails'].filter((elem: any) => elem.id !== payload.id);
      }
      if (payload.type === 'sms') {
        const filter = state['sms'].filter((elem: any) => elem.id !== payload.id);
        state['sms'] = filter
      }
      state.isDeleteFinished = true;
      state.isDeleteSuccess = true;
    });
    builder.addCase(deleteTemplate.rejected, (state, action: any) => {
      state.error = action.payload;
      state.isDeleteFinished = true;
      state.isDeleteInProgress = false;
    });

    builder.addCase(updateTemplate.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateTemplate.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (payload.type === 'email') {
        state['emails'] = state['emails'].map((elem: any) => elem.id === payload.item.id ? { ...payload.item } : elem);
      }
      if (payload.type === 'sms') {
        state['sms'] = state['sms'].map((elem: any) => elem.id === payload.item.id ? { ...payload.item } : elem);
      }
    });
    builder.addCase(updateTemplate.rejected, (state) => {
      state.isLoading = false;
    });
  }
});

export const getAllTemplates = createAsyncThunk('templates/getAll', async (_, { dispatch, rejectWithValue }) => {
  try {
    const response = await getAllTemplatesAPI();
    return response.data;
  } catch (err: any) {
    dispatch(showToast({ message: err.response.data.message, severity: 'failed' }));
    return rejectWithValue(err.response.data.message);
  }
});

export const addTemplate = createAsyncThunk('templates/addTemplate', async ({ type, data }: any, {
  dispatch,
  rejectWithValue
}) => {
  try {
    const response = await addTemplateAPI(type, data);
    dispatch(showToast({ message: 'Template created!', severity: 'success' }));
    return { item: response.data, type };
  } catch (err: any) {
    dispatch(showToast({ message: err.response.data.message, severity: 'failed' }));
    return rejectWithValue(err.response.data.message);
  }
});
export const updateTemplate = createAsyncThunk('templates/updateTemplate', async ({ type, id, content }: any, {
  dispatch,
  rejectWithValue
}) => {
  try {
    const response = await updateTemplateAPI(type, id, content);
    dispatch(showToast({ message: 'Template updated!', severity: 'success' }));
    return { item: response.data, type };
  } catch (err: any) {
    dispatch(showToast({ message: err.response.data.message, severity: 'failed' }));
    return rejectWithValue(err.response.data.message);
  }
});
export const deleteTemplate = createAsyncThunk('templates/deleteTemplate', async ({ type, id }: any, {
  dispatch,
  rejectWithValue
}) => {
  try {
    await deleteTemplateAPI(type, id);
    dispatch(showToast({ message: 'Template deleted!', severity: 'success' }));
    return { type, id };
  } catch (err: any) {
    dispatch(showToast({ message: err.response.data.message, severity: 'failed' }));
    return rejectWithValue(err.response.data.message);
  }
});
export const { setIsDeleteSuccess, setSuccess } = templatesSlice.actions;

export const templatesReducer = templatesSlice.reducer;