import { NotificationType } from '../app/features/notifications/notificationSlice';

export const formatNotifications = (arr: NotificationType[]) => {
  return arr.map(n => {
    if (n.notification === 'Shared' && n.creator?.fullName) {
      return {...n, notification: `Shared by ${n.creator.fullName.split(' ')[ 0 ]}`};
    }
    if (n.notification === 'Callback') {
      return {...n, notification: `📞 ${n.borrower.callbackDay} ${n.borrower.callbackTime} hours`};
    }
    if (n.notification === 'Assigned' && n.creator?.fullName) {
      return {...n, notification: `${n.creator.fullName.split(' ')[ 0 ]} assigned this loan to you`};
    }
    return n;
  });
};