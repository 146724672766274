import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { sendSmsAPI, sendEmailAPI, PayloadSendEmailType, PayloadSendSmsType } from './commsAPI';
import { showToast } from '../ui/uiSlice';
import { RootState } from '../../store';

type StateType = {
  isLoading: boolean
  isSent: boolean
  error: null | string
}

export const commsSlice = createSlice({
  name: 'comms',
  initialState: {
    isLoading: false,
    isSent: false,
    error: null,
  } as StateType,
  reducers: {
    setIsSent: (state, payload) => {
      state.isSent = payload.payload
    }
  },

  extraReducers: (builder) => {
    builder.addCase(sendEmail.pending, (state) => {
      state.isLoading = true;
      state.isSent = false;
    });
    builder.addCase(sendEmail.fulfilled, (state) => {
      state.isSent = true;
      state.isLoading = false;
    });
    builder.addCase(sendEmail.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(sendSms.pending, (state) => {
      state.isLoading = true;
      state.isSent = false;
    });
    builder.addCase(sendSms.fulfilled, (state) => {
      state.isSent = true;
      state.isLoading = false;
    });
    builder.addCase(sendSms.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const sendEmail = createAsyncThunk('comms/sendEmail', async(data: PayloadSendEmailType, {dispatch, getState, rejectWithValue}) => {
  try {
    const id = (getState() as RootState).customers.customer.userId;
    const response = await sendEmailAPI(id, data);
    return response.data;
  } catch (err: any) {
    dispatch(showToast({message: err.response.data.message, severity: 'failed'}));
    return rejectWithValue(err.response.data.message);
  }
});
export const sendSms = createAsyncThunk('comms/sendSms', async(data: PayloadSendSmsType, {dispatch, getState, rejectWithValue}) => {
  try {
    const id = (getState() as RootState).customers.customer.userId;
    const response = await sendSmsAPI(id, data);
    return response.data;
  } catch (err: any) {
    dispatch(showToast({message: err.response.data.message, severity: 'failed'}));
    return rejectWithValue(err.response.data.message);
  }
});

export const {setIsSent} = commsSlice.actions;

export const commsReducer = commsSlice.reducer;