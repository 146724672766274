import React from 'react';
import Select, { ISelectOption } from '../../CustomSelect/CustomSelect';
import CardContainer from '../CardContainer/CardContainer';
import './LoanModal.scss';
import { generateSequence, getLoanSettings, tapCalculator } from '../../../utils/tapCalc';
import BtnGroup from '../../BtnGroup/BtnGroup';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { LoanType, setSuccess, updateLoan } from '../../../app/features/customers/customersSlice';
import Input from '../../input/Input';
import { formatValueToCurrency } from '../../../utils/formatValueToCurrency';
import moment from 'moment';

interface ILoanModal {
  originalLoan: LoanType,
  alternativeLoan: LoanType,
  loanPurpose: string,
  firstPaymentDate: string | null,
  paymentDay: number | null,
  applicationStatus: string | null;
}

const LoanModal = ({
  loanPurpose,
  originalLoan,
  alternativeLoan,
  firstPaymentDate,
  paymentDay,
  applicationStatus,
}: ILoanModal) => {

  const hasAlternativeLoan = alternativeLoan ? Object.values(alternativeLoan).some(v => v) : null;

  const aprList = [
    {
      value: 39.9,
      label: '39.9% APR',
    },
    {
      value: 59.9,
      label: '59.9% APR',
    },
    {
      value: 79.9,
      label: '79.9% APR',
    },
  ];

  const {isModalLoading, isSuccess} = useAppSelector((state) => state.customers);

  const loanAmountValue = hasAlternativeLoan ? alternativeLoan.amount : originalLoan.amount;
  const loanTermValue = hasAlternativeLoan ? alternativeLoan.term : originalLoan.term;
  const loanRepayValue = hasAlternativeLoan ? alternativeLoan.repayment : originalLoan.repayment;
  const loanTotalValue = hasAlternativeLoan ? alternativeLoan.total : originalLoan.total;
  const loanCostValue = hasAlternativeLoan ? alternativeLoan.cost : originalLoan.cost;
  const aprValue1 = hasAlternativeLoan ? alternativeLoan.apr : originalLoan.apr;

  React.useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        setTouched(false);
        dispatch(setSuccess(false));
      }, 5000);
    }
  }, [ isSuccess ]);

  const dispatch = useAppDispatch();

  const [ aprValue, setAprValue ] = React.useState(aprValue1);
  const [ loanAmount, setLoanAmount ] = React.useState<any>(loanAmountValue);
  const [ selector, setSelector ] = React.useState(tapCalculator(loanAmountValue, aprValue));
  const [ loanRepay, setLoanRepay ] = React.useState<any>(loanRepayValue);
  const [ loanTerm, setLoanTerm ] = React.useState<any>(loanTermValue);
  const [ loanTotal, setLoanTotal ] = React.useState(loanTotalValue);
  const [ loanCost, setLoanCost ] = React.useState(loanCostValue);
  const [ loanSettings, setLoanSettings ] = React.useState(getLoanSettings(aprValue));
  const [ loanAmountList, setLoanAmountList ] = React.useState(generateSequence(loanSettings.start, loanSettings.end, loanSettings.step));
  const [ view, setView ] = React.useState(hasAlternativeLoan ? 'alternative' : 'original');
  const [ touched, setTouched ] = React.useState(false);

  const formatLoanAmountForSelect = (arr: any[]): ISelectOption[] => {
    return arr.map(elem => {
      return {
        value: elem,
        label: formatValueToCurrency(elem).slice(0, -3),
      };
    });
  };
  const formatLoanRepayForSelect = (arr: any[]): ISelectOption[] => {
    return arr.map(elem => {
      return {
        value: elem.repay,
        label: formatValueToCurrency(elem.repay),
      };
    });
  };
  const formatLoanTermForSelect = (arr: any[]): ISelectOption[] => {
    return arr.map(elem => {
      return {
        value: elem.term,
        label: elem.term + ' months',
      };
    });
  };
  const formattedLoanAmountList: ISelectOption[] = formatLoanAmountForSelect(loanAmountList);
  const formattedLoanRepayList: ISelectOption[] = formatLoanRepayForSelect(selector);
  const formattedLoanTermList: ISelectOption[] = formatLoanTermForSelect(selector);

  const handleAprChange = (apr: number) => {
    setAprValue(apr);
    const l = getLoanSettings(apr);
    const list = generateSequence(l.start, l.end, l.step);
    if (!list.includes(loanAmount)) {
      setLoanAmount(list[ list.length - 1 ]);
    }
    setLoanAmountList(list);
    const selectorObj = tapCalculator(loanAmount, apr);
    setSelector(selectorObj);
    setLoanRepay(selectorObj[ 0 ].repay);
    setLoanTerm(selectorObj[ 0 ].term);
    setLoanTotal(selectorObj[ 0 ].total);
    setLoanCost(selectorObj[ 0 ].cost);
    setTouched(true);
  };
  const handleCancelClick = () => {
    setLoanAmount(loanAmountValue);
    setSelector(tapCalculator(loanAmountValue, aprValue1));
    setLoanRepay(loanRepayValue);
    setLoanTerm(loanTermValue);
    setLoanTotal(loanTotalValue);
    setLoanCost(loanCostValue);
    setTouched(false);
  };
  const handleAmountChange = (obj: any) => {
    setLoanAmount(obj.value);
    const selectorObj = tapCalculator(obj.value, aprValue1);
    setSelector(selectorObj);
    setLoanRepay(selectorObj[ 0 ].loanRepay);
    setLoanTerm(selectorObj[ 0 ].term);
    setLoanTotal(selectorObj[ 0 ].total);
    setLoanCost(selectorObj[ 0 ].cost);
    setTouched(true);
  };
  const handleRepayChange = (obj: any) => {
    setLoanRepay(obj.value);
    const loan = selector.find((elem: any) => elem.repay === obj.value);
    setLoanTerm(loan.term);
    setLoanTotal(loan.total);
    setLoanCost(loan.cost);
    setTouched(true);
  };
  const handleTermChange = (obj: any) => {
    setLoanTerm(obj.value);
    const loan = selector.find((elem: any) => elem.term === obj.value);
    setLoanRepay(loan.repay);
    setLoanTotal(loan.total);
    setLoanCost(loan.cost);
    setTouched(true);
  };

  return (
      <CardContainer className="loan-modal" size={'lg'} comment={'Information about the loan'}>
        <div className="loan-modal__container">
          <div className="loan-modal__selector">
            {hasAlternativeLoan && (
                <button className={view === 'alternative' ? 'active' : ''} onClick={() => setView('alternative')}>
                  A
                </button>)}
            <button className={view === 'original' ? 'active' : ''} onClick={() => setView('original')}>
              O
            </button>
          </div>
          {hasAlternativeLoan ?
              view === 'alternative' ?
                  <div className="loan-modal-fields-block">
                    <Select className="loan-modal__select"
                        optionsList={formattedLoanAmountList}
                        onChange={handleAmountChange}
                        selectedOption={formattedLoanAmountList.find((o: ISelectOption) => o.value === loanAmount)} />
                    <Select className="loan-modal__select"
                        optionsList={aprList}
                        onChange={(e: any) => handleAprChange(e.value)}
                        selectedOption={aprList.find((o: ISelectOption) => o.value === aprValue)} />
                    <Select className="loan-modal__select"
                        optionsList={formattedLoanRepayList}
                        onChange={handleRepayChange}
                        selectedOption={formattedLoanRepayList.find((o: ISelectOption) => o.value === loanRepay)} />
                    <Select className="loan-modal__select"
                        optionsList={formattedLoanTermList}
                        onChange={handleTermChange}
                        selectedOption={formattedLoanTermList.find((o: ISelectOption) => o.value === loanTerm)} />
                  </div> : <div className="loan-modal-fields-block">
                    <Input className="loan-modal__select" value={formatValueToCurrency(originalLoan.amount)} disabled />
                    <Input className="loan-modal__select" value={`${originalLoan.apr}% APR`} disabled />
                    <Input className="loan-modal__select" value={formatValueToCurrency(originalLoan.repayment)} disabled />
                    <Input className="loan-modal__select" value={`${originalLoan.term} months`} disabled />
                  </div> :
              <div className="loan-modal-fields-block">
                <Select className="loan-modal__select"
                    optionsList={formattedLoanAmountList}
                    onChange={handleAmountChange}
                    selectedOption={formattedLoanAmountList.find((o: ISelectOption) => o.value === loanAmount)} />
                <Select className="loan-modal__select"
                    optionsList={aprList}
                    onChange={(e: any) => handleAprChange(e.value)}
                    selectedOption={aprList.find((o: ISelectOption) => o.value === aprValue)} />
                <Select className="loan-modal__select"
                    optionsList={formattedLoanRepayList}
                    onChange={handleRepayChange}
                    selectedOption={formattedLoanRepayList.find((o: ISelectOption) => o.value === loanRepay)} />
                <Select className="loan-modal__select"
                    optionsList={formattedLoanTermList}
                    onChange={handleTermChange}
                    selectedOption={formattedLoanTermList.find((o: ISelectOption) => o.value === loanTerm)} />
              </div>
          }
          <div className="loan-modal__details-block details">
            <p className="details__item">
              {hasAlternativeLoan && view === 'original' ?
                  formatValueToCurrency(originalLoan.total) :
                  formatValueToCurrency(loanTotal)} total repayable
            </p>
            <p className="details__item">
              {hasAlternativeLoan && view === 'original' ?
                  formatValueToCurrency(originalLoan.cost) :
                  formatValueToCurrency(loanCost)} total charge for credit
            </p>
          </div>
          {applicationStatus === 'Paid out' && <div className="loan-modal__details-block details">
            <div className='flex'>
              <div className='details__item'>
                First payment
              </div>
              <div className='details__item'>

                {
                  firstPaymentDate ?
                      `${moment(firstPaymentDate).format("Do MMMM YYYY")}`
                  : 'unknown'
                }
              </div>
            </div>
            <div className='flex'>
              <div className='details__item'>
                Regular payment
              </div>
              <div className='details__item'>
                {/*{moment(paymentDay).format("Do MMMM YYYY")}*/}
                {moment(paymentDay).format("Do")} day of each month
              </div>
            </div>
          </div>}
          <div className="loan-modal__reason">
            <div className={'loan-modal__quote'} />
            {loanPurpose}
          </div>
          <div className="loan-modal__btn-group">
            <BtnGroup
                isActive={touched}
                onClick={() => dispatch(updateLoan({loanAmount, loanTerm, loanRepay, apr: aprValue}))}
                onCancel={handleCancelClick}
                isLoading={isModalLoading}
                isSuccess={isSuccess}
            />
          </div>
        </div>
      </CardContainer>
  );
};

export default LoanModal;
