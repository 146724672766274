import { createSlice } from '@reduxjs/toolkit';

export type StatusType = 'idle' | 'loading' | 'success' | 'failed'

export type InitialStateType = {
  severity: StatusType,
  message: string,
  open: boolean,
}

const initialState: InitialStateType = {
  open: false,
  message: '',
  severity: 'success',

};

const slice = createSlice({
  name: 'ui',
  initialState: initialState,
  reducers: {
    showToast: (state, {payload}) => {
      state.message = payload.message;
      state.open = true;
      state.severity = payload.severity;
    },
    clearToast: (state) => {
      state.open = false;
      state.message = '';
    },
  },
});

export const {showToast, clearToast} = slice.actions;

export const uiReducer = slice.reducer;