import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import Input from '../../components/input/Input';
import Button from '../../components/Button/Button';
import logo from '../../assets/fuse.svg';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { signIn } from '../../app/features/auth/authSlice';
import './LoginPage.scss';
import loader from '../../assets/loader.gif';

export interface AuthData {
  email: string;
  password: string;
}

const LoginPage = () => {
  const dispatch = useAppDispatch();
  const { isLoading, isAuth, error } = useAppSelector((state) => state.auth);

  const validation = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Please enter email'),
    password: Yup.string().required('Please enter password')
  });

  const navigate = useNavigate();

  return (
    <div className="login">
      <div className="login__container">
        <div className="login__content-wrap">
          <div className="login__central-block">
            <div className="login__left-size">
              <h1 className="login__title">Fuse.</h1>
              <h2 className="login__subtitle">Familio underwriting system</h2>
            </div>
            <Formik
              initialValues={{
                email: '',
                password: ''
              }}
              validationSchema={validation}
              onSubmit={async (values, options) => {
                options.setSubmitting(false);
                dispatch(signIn(values)).then(() => {
                  navigate('/');
                });
              }}
            >
              {({ errors, touched }) => (
                <Form className="login__form form" autoComplete="on">
                  <div className="form__container">
                    <Field id="email"
                           name="email"
                           placeholder="Username"
                           error={errors.email && touched.email ? errors.email : error ? true : ''}
                           as={Input} />
                    <Field id="password"
                           name="password"
                           type="password"
                           placeholder="Password"
                           error={error}
                           as={Input} />
                    {isLoading ?
                      <div className="login__loader-wrapper">
                        <div className="login__loader">
                          <img src={loader} alt="loader" />
                        </div>
                      </div>
                      : <Button onClick={() => {
                      }} type="submit">Login</Button>}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <div className="login__footer">
        <div className="login__logo">
          <img src={logo} alt="logo" />
        </div>
        <p className="login__version">V1.0.2</p>
      </div>
    </div>
  );
};

export default LoginPage;