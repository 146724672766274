import React, { useEffect, useState } from 'react';
import './Dashboard.scss';
import nightMode from '../../assets/night.svg';
import dayMode from '../../assets/day.svg';
import maximise from '../../assets/maximise.svg';
import minimise from '../../assets/minimise.svg';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import Title from '../../components/Title/Title';
import { RootState, useAppDispatch } from '../../app/store';
import { useAppSelector } from '../../app/hooks';
import { getLatestStats } from '../../app/features/currentStats/currentStatsSlice';
import DashboardCard from './DashboardCard';
import ReactPlayer from 'react-player';
import beep from '../../assets/beep.mp3';

type DashboardPropsType = {
  theme: string
  setTheme: (theme: 'light' | 'dark' | 'overlay') => void;
}

const Dashboard = ({theme, setTheme}: DashboardPropsType) => {

  const fullWidth = useFullScreenHandle();

  const dispatch = useAppDispatch();
  const {stats} = useAppSelector((state: RootState) => state.latestStats);
  const [ isPlaying, setIsPlaying ] = useState<boolean>(false);
  const [success, setSuccess] = useState<number>(0)

  useEffect(() => {
    dispatch(getLatestStats());
    setSuccess(stats.totalToday.success)
    const interval = setInterval(() => dispatch(getLatestStats()), 30000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    return () => {
      setTheme('light');
    };
  }, []);

  if (stats.totalToday.success !== success) {
    const stat = success
    setSuccess(stats.totalToday.success)
    if (stats.totalToday.success > stat) {
      setIsPlaying(true)
    }
  }

  return (
      /* @ts-ignore */
      <FullScreen handle={fullWidth}>
        <ReactPlayer url={beep} playing={isPlaying}
            height={0}
            width={0}
            onEnded={() => {
              setIsPlaying(false);
            }}
        />
        <div className="dashboard" data-theme={theme}>
          <div className="dashboard__header">
            <Title text={'Business.'} />
            <div className="dashboard__theme-block">
              <button onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')}>
                <img src={theme === 'light' ? nightMode : dayMode} alt="" />
              </button>
              <button onClick={fullWidth.active ? fullWidth.exit : fullWidth.enter}>
                <img src={fullWidth.active ? minimise : maximise} alt="" />
              </button>
            </div>
          </div>
          <div className="dashboard__cards-block">
            <DashboardCard title={'Leads'}
                number={stats.totalToday.leads}
                customers={stats.latestCustomers.leads}
                comment={'All customers'}
                fullWidth={fullWidth.active} />
            <DashboardCard title={'Apps'}
                number={stats.totalToday.apps}
                customers={stats.latestCustomers.apps}
                comment={'Completed applications'}
                fullWidth={fullWidth.active} />
            <DashboardCard title={'Success'}
                number={stats.totalToday.success}
                customers={stats.latestCustomers.success}
                comment={'Successful applications'}
                fullWidth={fullWidth.active} />
          </div>
          <Title text={'Searches.'} />
          <div className="dashboard__cards-block">
            <DashboardCard title={'Credit'} number={stats.totalToday.credit} comment={'CallReport searches'} />
            <DashboardCard title={'Affordability'} number={stats.totalToday.affordability} comment={'Accountscore Connections'} />
            <DashboardCard title={'Sanctions'} number={stats.totalToday.validation} comment={'CallValidate searches'} />
          </div>
        </div>
      </FullScreen>
  );
};

export default Dashboard;