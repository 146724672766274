import React, { MutableRefObject } from 'react';
import MenuDropdown from './MenuDropdown';
import { MenuItemType } from './Menu';

type MenuItemPropsType = {
  items: MenuItemType
  isLoading: boolean
  key: number
}

const MenuItem = ({items, isLoading, key}: MenuItemPropsType) => {

  const [ dropdown, setDropdown ] = React.useState<boolean>(false);

  let ref: MutableRefObject<HTMLElement | undefined> = React.useRef<HTMLElement>();

  React.useEffect(() => {
    const handler = (event: any) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, [ dropdown ]);

  const onMouseEnter = () => {
    window.innerWidth > 960 && setDropdown(true);
  };

  const onMouseLeave = () => {
    window.innerWidth > 960 && setDropdown(false);
  };

  return (
      <li className="menu__item"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
      >
        {items.submenu ? (
            <>
              <button type="button" aria-haspopup="menu"
                  className="menu__btn"
                  aria-expanded={dropdown ? 'true' : 'false'}
                  onClick={() => setDropdown((prev) => !prev)}>
                {items.title}{' '}
              </button>
              <MenuDropdown key={key} isLoading={isLoading} submenus={items.submenu} dropdown={dropdown}
              />
            </>
        ) : (
            <button onClick={items.action} className="menu__btn">{items.title}</button>
        )}
      </li>
  );
};

export default MenuItem;
