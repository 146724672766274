import CustomAxios from '../../CustomAxios/CustomAxios';

export const getLeadsAPI = (startDate:string, endDate:string) => {
  return CustomAxios.get(`${process.env.REACT_APP_STATS_URL}/dev/leads?startDate=${startDate}&endDate=${endDate}`);
}
export const getAppsAPI = (startDate:string, endDate:string) => {
  return CustomAxios.get(`${process.env.REACT_APP_STATS_URL}/dev/apps?startDate=${startDate}&endDate=${endDate}`);
}
export const getIntroducedAPI = (startDate:string, endDate:string) => {
  return CustomAxios.get(`${process.env.REACT_APP_STATS_URL}/dev/introduced?startDate=${startDate}&endDate=${endDate}`);
}
export const getSuccessAPI = (startDate:string, endDate:string) => {
  return CustomAxios.get(`${process.env.REACT_APP_STATS_URL}/dev/success?startDate=${startDate}&endDate=${endDate}`);
}
export const getPaidAPI = (startDate:string, endDate:string) => {
  return CustomAxios.get(`${process.env.REACT_APP_STATS_URL}/dev/paid?startDate=${startDate}&endDate=${endDate}`);
}
export const getFundedAPI = (startDate:string, endDate:string) => {
  return CustomAxios.get(`${process.env.REACT_APP_STATS_URL}/dev/funded?startDate=${startDate}&endDate=${endDate}`);
}

export const getChartDataAPI = (startDate:string, endDate:string) => {
  return CustomAxios.get(`${process.env.REACT_APP_STATS_URL}/dev/stats?startDate=${startDate}&endDate=${endDate}`);
}
export const getJourneyDataAPI = (startDate:string, endDate:string) => {
  return CustomAxios.get(`${process.env.REACT_APP_STATS_URL}/dev/journey?startDate=${startDate}&endDate=${endDate}`);
}