import React from 'react';
import CardContainer from '../../CardContainer/CardContainer';
import editIcon from '../../../assets/edit.svg';

interface IAddress {
  address1: string,
  address2?: string,
  address3?: string,
  townOrCity: string,
  postCode: string
}

function combineAddress(a1: string, a2: string | undefined, a3: string | undefined, town: string, pc: string) {
  return [ ...arguments ].reduce((acc, val) => val ? [ ...acc, val ] : acc, []).join(', ');
}

function addDashes(sortCode: string | null) {
  return sortCode && sortCode.slice(0, 2) + '-' + (sortCode + '').slice(2, 4) + '-' + (sortCode + '').slice(4);
}

function addSpaces(mobilePhone: string) {
  return mobilePhone.slice(0, 5) + ' ' + mobilePhone.slice(5, 8) + ' ' + mobilePhone.slice(8);
}

interface IProps {
  mobilePhone: string,
  emailAddress: string,
  currAddress: IAddress,
  prevAddress: IAddress,
  sortCode: string | null,
  accountNumber: string | null,
  bankName: string,
  onClick: () => void,
  handleEmailClick: () => void,
  handleSMSClick: () => void,
  className: string
}

const DetailsInfoCard = ({
  onClick,
  handleSMSClick,
  handleEmailClick,
  mobilePhone,
  bankName,
  emailAddress,
  sortCode,
  accountNumber,
  currAddress,
  prevAddress,
  className,
}: IProps) => {

    let previousAddress = null;

  const currentAddress = combineAddress(currAddress.address1, currAddress.address2, currAddress.address3, currAddress.townOrCity,
      currAddress.postCode);
  if (prevAddress) {
      previousAddress = combineAddress(prevAddress.address1, prevAddress.address2, prevAddress.address3, prevAddress.townOrCity,
          prevAddress.postCode);
  }

  return (
      <CardContainer title={'Details'} comment={'Application data'} className={`card ${className}`}>
        <button onClick={handleSMSClick} className="card__link">
          {addSpaces(mobilePhone)}
        </button>
        <button onClick={handleEmailClick} className="card__link">
          {emailAddress}
        </button>
        <p className="card__text">
          {currentAddress}
        </p>
        {previousAddress && <p className="card__text">
          {previousAddress}
        </p>}
        <p className="card__text">
          {addDashes(sortCode)}, {accountNumber}
        </p>
        <p className="card__text">
          {bankName}
        </p>
        <button className="card__edit-btn" onClick={onClick}>
          <img src={editIcon} alt="" />
        </button>
      </CardContainer>
  );
};

export default DetailsInfoCard;
