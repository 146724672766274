import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Fade } from "react-awesome-reveal";

type ModalType = {
  show: boolean,
  onClose: () => void
  closeOnBackground?: boolean
}

const Modal = ({show, children, closeOnBackground = true, onClose}: React.PropsWithChildren<ModalType>) => {

  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = '15px';
    }
    return () => {
      document.body.style.overflow = 'unset';
      document.body.style.paddingRight = '0px';
    };
  }, [ show ]);

  if (!show) return null;

  return ReactDOM.createPortal(
      <div
          className="react-chained-modal-cover"
          id="react-chained-modal-portal"
          onClick={(e: any) => {
            if (
                e.target.id === 'react-chained-modal-portal' &&
                closeOnBackground
            ) {
              onClose();
            }
          }}
      >
        <div className="overlay" onClick={onClose} />
        <Fade>
          {children}
        </Fade>
      </div>,
      document.body,
  );
};

export default Modal;
