import React from 'react';
import './NotificationPage.scss';
import Title from '../../components/Title/Title';
import CustomLink from '../../components/CustomLink/CustomLink';
import { RootState, useAppDispatch } from '../../app/store';
import { useAppSelector } from '../../app/hooks';
import { format } from 'date-fns';
import { markNotificationAsRead, markNotificationsAsRead, setSuccess } from '../../app/features/notifications/notificationSlice';
import { formatNotifications } from '../../utils/formatNotifications';
import { formatStatus } from '../../helpers/formatStatus';
import icon from '../../assets/notification.svg';
import DialogPopupWrapper from '../../components/DialogPopup/DialogPopup';
import Modal from '../../components/Modal/Modal';
import BtnGroup from '../../components/BtnGroup/BtnGroup';

const NotificationPage = () => {

  const dispatch = useAppDispatch();
  const {notifications, isLoading, isSuccess} = useAppSelector((state: RootState) => state.notifications);
  const [ showModal, setShowModal ] = React.useState<boolean>(false);

  const handleNotificationClick = (id: number, isRead: boolean) => {
    if (isRead) return;
    dispatch(markNotificationAsRead(id));
  };

  if (isSuccess) {
    setShowModal(false)
    dispatch(setSuccess(false))
  }

  const formattedNotifications = formatNotifications(notifications);

  return (
      <div className="notifications">
        <div className={'notifications__header'}>
          <Title text={'Notifications.'} />
          <button onClick={() => setShowModal(true)}>
            <img src={icon} alt="" />
          </button>
        </div>
        <div className="notifications__table-container">
          <table className="notifications__table table">
            <thead className="table__head">
            <tr className="table__row">
              <th></th>
              <th>Name</th>
              <th>Stage</th>
              <th>Status</th>
              <th>Notification</th>
              <th>Date</th>
            </tr>
            </thead>
            <tbody className="table__body">
            {formattedNotifications.map((elem: any) => {
              return (
                  <tr key={elem.id} className="table__row">
                    <td className={elem.isRead ? '' : 'unread'}></td>
                    <td><CustomLink onClick={() => handleNotificationClick(elem.id, elem.isRead)}
                        to={`/customers/${elem.borrower.userId}`}>{elem.borrower.fullName}</CustomLink></td>
                    <td>{elem.borrower.applicationStage}</td>
                    <td>{elem.borrower.applicationStatus && formatStatus(elem.borrower.applicationStatus)}</td>
                    <td>{elem.notification}</td>
                    <td>{format(new Date(elem.date), 'dd/MM/y')}</td>
                  </tr>
              );
            })}
            </tbody>
          </table>
        </div>
        <Modal show={showModal} onClose={() => setShowModal(false)}>
          <DialogPopupWrapper title={'Mark notifications as read'} className={'modal'}>
            <div className="modal__body">
              <p className="modal__text">Are you sure you want to mark all notifications as read? You can’t undo this. </p>
              <BtnGroup
                  className="modal__btn"
                  activeBtnName={'Yes'}
                  isLoading={isLoading}
                  onCancel={() => setShowModal(false)}
                  onClick={() => dispatch(markNotificationsAsRead())} />
            </div>
          </DialogPopupWrapper>
        </Modal>
      </div>
  );
};

export default NotificationPage;