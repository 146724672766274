import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  declineCustomerAPI,
  doNotLendCustomerAPI,
  getCreditAPI,
  getSanctionsAPI,
  notTakenUpAPI,
  payoutCustomerAPI,
  refreshAffordabilityCustomerAPI,
} from './commandsAPI';
import { showToast } from '../ui/uiSlice';
import { setAffordability, setCredit, setSanctions, setStatus } from '../customers/customersSlice';
import { RootState } from '../../store';

type StateType = {
  isLoading: boolean
  isModalLoading: boolean
  isModalSuccess: boolean
  isRequestFinished: boolean
  error: null | string
}

export const commandsSlice = createSlice({
  name: 'commands',
  initialState: {
    isLoading: false,
    isModalSuccess: false,
    isModalLoading: false,
    isRequestFinished: false,
    error: null,
  } as StateType,
  reducers: {
    setRequestIsFinished: (state, {payload}) => {
      state.isRequestFinished = payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(setDoNotLend.pending, (state) => {
      state.isModalLoading = true;
      state.isModalSuccess = false;
    });
    builder.addCase(setDoNotLend.fulfilled, (state) => {
      state.isModalLoading = false;
      state.isModalSuccess = true;
    });
    builder.addCase(setDoNotLend.rejected, (state) => {
      state.isModalLoading = false;
    });

    builder.addCase(setPayout.pending, (state) => {
      state.isModalLoading = true;
      state.isModalSuccess = false;
      state.isRequestFinished = false;
    });
    builder.addCase(setPayout.fulfilled, (state) => {
      state.isModalLoading = false;
      state.isModalSuccess = true;
      state.isRequestFinished = true;
    });
    builder.addCase(setPayout.rejected, (state) => {
      state.isModalLoading = false;
      state.isRequestFinished = true;
    });

    builder.addCase(setDecline.pending, (state) => {
      state.isModalLoading = true;
      state.isModalSuccess = false;
    });
    builder.addCase(setDecline.fulfilled, (state) => {
      state.isModalLoading = false;
      state.isModalSuccess = true;
    });
    builder.addCase(setDecline.rejected, (state) => {
      state.isModalLoading = false;
    });

    builder.addCase(setNotTakenUp.pending, (state) => {
      state.isModalLoading = true;
      state.isModalSuccess = false;
    });
    builder.addCase(setNotTakenUp.fulfilled, (state) => {
      state.isModalLoading = false;
      state.isModalSuccess = true;
    });
    builder.addCase(setNotTakenUp.rejected, (state) => {
      state.isModalLoading = false;
    });

    builder.addCase(runCredit.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(runCredit.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(runCredit.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(runAffordability.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(runAffordability.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(runAffordability.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(runSanctions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(runSanctions.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(runSanctions.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const runSanctions = createAsyncThunk('commands/sanctions',
    async(data, {dispatch, getState, rejectWithValue}) => {
      try {
        const id = (getState() as RootState).customers.customer.userId;
        const response = await getSanctionsAPI(id);
        dispatch(setSanctions(response.data));
        return response.data;
      } catch (err: any) {
        dispatch(showToast({message: err.response.data.message, severity: 'failed'}));
        return rejectWithValue(err.response.data.message);
      }
    });
export const runCredit = createAsyncThunk('commands/credit',
    async(data, {dispatch, getState, rejectWithValue}) => {
      try {
        const id = (getState() as RootState).customers.customer.userId;
        const response = await getCreditAPI(id);
        dispatch(setCredit(response.data));
        return response.data;
      } catch (err: any) {
        dispatch(showToast({message: err.response.data.message, severity: 'failed'}));
        return rejectWithValue(err.response.data.message);
      }
    });
export const runAffordability = createAsyncThunk('commands/affordability',
    async(data, {dispatch, getState, rejectWithValue}) => {
      try {
        const id = (getState() as RootState).customers.customer.userId;
        const response = await refreshAffordabilityCustomerAPI(id);
        dispatch(setAffordability(response.data));
        return response.data;
      } catch (err: any) {
        dispatch(showToast({message: err.response.data.message, severity: 'failed'}));
        return rejectWithValue(err.response.data.message);
      }
    });

export const setDoNotLend = createAsyncThunk('commands/doNotLend',
    async(data: { password: string }, {dispatch, getState, rejectWithValue}) => {
      try {
        const id = (getState() as RootState).customers.customer.userId;
        const response = await doNotLendCustomerAPI(id, data);
        dispatch(setStatus(response.data));
        return response.data;
      } catch (err: any) {
        dispatch(showToast({message: err.response.data.message, severity: 'failed'}));
        return rejectWithValue(err.response.data.message);
      }
    });
export const setPayout = createAsyncThunk('commands/payout',
    async(data: { password: string }, {dispatch, getState, rejectWithValue}) => {
      try {
        const id = (getState() as RootState).customers.customer.userId;
        const response = await payoutCustomerAPI(id, data);
        dispatch(setStatus(response.data));
        return response.data;
      } catch (err: any) {
        dispatch(showToast({message: err.response.data.message, severity: 'failed'}));
        return rejectWithValue(err.response.data.message);
      }
    });
export const setDecline = createAsyncThunk('commands/decline',
    async(data: { password: string, reason: string }, {dispatch, getState, rejectWithValue}) => {
      try {
        const id = (getState() as RootState).customers.customer.userId;
        const response = await declineCustomerAPI(id, data);
        dispatch(setStatus(response.data));
        return response.data;
      } catch (err: any) {
        dispatch(showToast({message: err.response.data.message, severity: 'failed'}));
        return rejectWithValue(err.response.data.message);
      }
    });
export const setNotTakenUp = createAsyncThunk('commands/notTakenUp',
    async(data: { password: string }, {dispatch, getState, rejectWithValue}) => {
      try {
        const id = (getState() as RootState).customers.customer.userId;
        const response = await notTakenUpAPI(id, data);
        dispatch(setStatus(response.data));
        return response.data;
      } catch (err: any) {
        dispatch(showToast({message: err.response.data.message, severity: 'failed'}));
        return rejectWithValue(err.response.data.message);
      }
    });

export const {setRequestIsFinished} = commandsSlice.actions;

export const commandsReducer = commandsSlice.reducer;