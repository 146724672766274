import React from 'react';
import editIcon from '../../../assets/edit.svg';
import capitalizeFirstLetter from '../../../utils/capitalizeFirstLetter';
import CardContainer from '../../CardContainer/CardContainer';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setSuccess } from '../../../app/features/customers/customersSlice';

interface IProps {
  fullName: string,
  dob: string
  agreementNumber?: string
  prefix: string
  className?: string
  onClick?: () => void
}

const ApplicantInfoCard = ({onClick, prefix, fullName, dob, agreementNumber, className}: IProps) => {

  const prefixCapitalized = capitalizeFirstLetter(prefix);

  return (
      <CardContainer title={'Applicant'} comment={'Information about the applicant'} className={`card ${className}`}>
        <h1 className="card__info">
          {prefixCapitalized} {fullName}
        </h1>
        <p className="card__text">
          {dob}
        </p>
        <p className="card__text">
          {agreementNumber || ''}
        </p>
        <button onClick={onClick} className="card__edit-btn">
          <img src={editIcon} alt="" />
        </button>
      </CardContainer>
  );
};

export default ApplicantInfoCard;
