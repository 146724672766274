// @ts-nocheck

const round = (number, n) => +number.toFixed(n);

export const getLoanSettings = (apr) => {
  const limits = {
    39.9: {
      start: 1000,
      end: 5000,
      step: 250,
      terms: [ 12, 24, 36, 48, 60 ],
      additionalTermsStartAmount: 2000,
    },
    49.9: {
      start: 1000,
      end: 5000,
      step: 250,
      terms: [ 12, 24, 36, 48, 60 ],
      additionalTermsStartAmount: 2000,
    },
    59.9: {
      start: 1000,
      end: 3000,
      step: 250,
      terms: [ 12, 24, 36 ],
      additionalTermsStartAmount: null,
    },
    79.9: {
      start: 1000,
      end: 2000,
      step: 250,
      terms: [ 12, 24 ],
      additionalTermsStartAmount: null,
    },
  };
  return limits[ apr ];
};

function* range(start, end, step) {
  while (start <= end) {
    yield start;
    start += step;
  }
}

export const generateSequence = (start, end, step) => Array.from(range(start, end, step));

const getLoanTerms = ({amount, terms, additionalTermsStartAmount}) => {
  const maxTerm = amount >= additionalTermsStartAmount ? terms[ terms.length - 1 ] : terms[ terms.length - 2 ];
  return generateSequence(terms[ 0 ], maxTerm, 12);
};

export function tapCalculator(loanAmount: any, apr: any = 59.9) {

  const loanSettings = getLoanSettings(apr);
  const result = [];
  const monthlyRate = ((1 + (apr / 100)) ** (1 / 12)) - 1;
  const terms = getLoanTerms({
    amount: loanAmount,
    terms: loanSettings.terms,
    additionalTermsStartAmount: loanSettings.additionalTermsStartAmount,
  });
  terms.forEach(term => {
    const premium = loanAmount * (monthlyRate / (1 - ((1 + monthlyRate) ** -(term))));
    const tap = premium * term;
    const cost = tap - loanAmount;
    result.push({
      term,
      repay: round(premium, 2),
      total: round(tap, 2),
      cost: round(cost, 2),
    });
  });
  return result;
}