import React, { ChangeEvent } from 'react';
import './Input.scss';
import cx from 'classnames';
import InputMask from 'react-input-mask';

interface IInput {
  value: string | number;
  type?: string;
  name?: string;
  id?: string;
  placeholder?: string;
  onChange?: (value: ChangeEvent<HTMLInputElement>) => void;
  error?: string | boolean | undefined;
  className?: string;
  label?: string;
  mask?: string

  [ x: string ]: any;
}

const Input = ({
  disabled,
  id,
  name,
  type,
  value,
  placeholder,
  error,
  className,
  label,
  onChange,
  mask = '',
  ...restInputProps
}: IInput) => {

  return (
      <div className={`input ${className}`}>
        {label && <label htmlFor={name}>
          {label}
        </label>}
        <InputMask
            disabled={disabled}
            type={type}
            name={name}
            id={id}
            onChange={onChange}
            value={value}
            mask={mask}
            placeholder={placeholder}
            className={cx({
              error: !!error,
            })}
            {...restInputProps}
        />
        {!!error && <span className="error-text">{error}</span>}
      </div>

  );
};

export default Input;