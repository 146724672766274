import React, { useEffect, useState } from 'react';
import './Comms.scss';
import SmsContainer from '../../../components/Comms/SmsContainer';
import { getAllTemplates, SmsTemplateType } from '../../../app/features/templates/templatesSlice';
import { RootState, useAppDispatch } from '../../../app/store';
import { useAppSelector } from '../../../app/hooks';
import { formatToSelect } from '../../../utils/formatToSelect';
import { ISelectOption } from '../../../components/CustomSelect/CustomSelect';
import { useOutletContext } from 'react-router-dom';
import { sendSms, setIsSent } from '../../../app/features/comms/commsSlice';

const SmsPage = () => {
  const [ customer ] = useOutletContext() as any;
  const editorRef: React.MutableRefObject<any | null> = React.useRef(null);

  const dispatch = useAppDispatch();
  const {sms} = useAppSelector((state: RootState) => state.templates);
  const {isLoading, isSent} = useAppSelector((state: RootState) => state.comms);

  const [ templates, setTemplates ] = useState<any[]>([]);
  const [ content, setContent ] = React.useState('');
  const [ contentTouched, setContentTouched ] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState<ISelectOption | null>(null)

  useEffect(() => {
    if (!sms.length) {
      dispatch(getAllTemplates());
    }
  }, []);

  useEffect(() => {
    setTemplates(formatToSelect(sms));
  }, [  sms ]);

  useEffect(() => {
    if (isSent) {
      setTimeout(() => {
        dispatch(setIsSent(false));
        handleClear()
      }, 5000);
    }
  },[isSent])

  const handleSelectedTemplate = (obj: ISelectOption) => {
    setSelectedOption(obj)
    const selectedTemplate = JSON.parse(JSON.stringify(sms.find((t): boolean => t.id === obj.value) as SmsTemplateType));
    //replace variable with customer firstName
    selectedTemplate.text = selectedTemplate.text.replace(
        '${customer.fullName}', customer.fullName.split(' ')[ 0 ]);
    editorRef.current.clearContent();
    editorRef.current.insertContent(selectedTemplate.text);
  };

  const handleSend = () => {
    dispatch(sendSms({message: content}));
  };

  const handleClear = () => {
    setSelectedOption(null)
    setContent( '')
    setContentTouched(false);
    editorRef.current.clearContent();
  }

  const handleContentChanges = (content: string) => {
    setContent(content as any);
    setContentTouched(true);
  };

  return (
      <div className={`comms-page`}>
        <div className={`comms-page__container`}>
          <SmsContainer
              options={templates}
              selectedOption={selectedOption}
              setSelectedOption={handleSelectedTemplate}
              handleCancel={handleClear}
              handleSend={handleSend}
              contentTouched={contentTouched}
              content={content}
              editorRef={editorRef}
              isLoading={isLoading}
              isSent={isSent}
              setContent={handleContentChanges}
              className={`comms-page__email-container`}
          />
        </div>
      </div>
  );
};

export default SmsPage;
