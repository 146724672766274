import CustomAxios from '../../CustomAxios/CustomAxios';

export const getAllTemplatesAPI = () => {
  return CustomAxios.get(`${process.env.REACT_APP_TEMPLATES_URL}/dev/templates`);
};

export const deleteTemplateAPI = (type: any, id: number) => {
  return CustomAxios.delete(`${process.env.REACT_APP_TEMPLATES_URL}/dev/templates/${type}/${id}`);
};

export const addTemplateAPI = (type: any, data: any) => {
  return CustomAxios.post(`${process.env.REACT_APP_TEMPLATES_URL}/dev/templates/${type}`, data);
};

export const updateTemplateAPI = (type: any, id: number, content: any) => {
  return CustomAxios.put(`${process.env.REACT_APP_TEMPLATES_URL}/dev/templates/${type}/${id}`, { content });
};