import React from 'react';
import ChainedModal from '../../ChainedModal/ChainedModal';
import DialogPopupWrapper from '../../DialogPopup/DialogPopup';
import BtnGroup from '../../BtnGroup/BtnGroup';
import Button from '../../Button/Button';
import '../DialogModal.scss';
import { useAppDispatch } from '../../../app/hooks';
import { deleteTemplate } from '../../../app/features/templates/templatesSlice';

const Modal1 = (props: any) => {

  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(deleteTemplate({type: props.type, id: props.id}));
    props.showNext();
  };

  return (
      <DialogPopupWrapper title={'Warning'} className={'modal'}>
        <div className="modal__body">
          <p>
            Are you sure you wish to delete this template?
          </p>
          <BtnGroup
              className="modal__btn"
              activeBtnName={'OK'}
              onCancel={() => props.closeChainedModal()}
              onClick={handleClick} />
        </div>
      </DialogPopupWrapper>
  );
};
const Modal2 = (props: any) => {

  return (
      <DialogPopupWrapper title={'Share'} className={'modal'}>
        <div className="modal__body">
          <p className="modal__text">Template deleted.</p>
          <Button onClick={() => props.closeChainedModal()} className={'modal__btn'}>OK</Button>
        </div>
      </DialogPopupWrapper>
  );
};

const WarningTemplateModal = ({onClose, visible, type, id}: any) => {
  return <ChainedModal
      visible={visible}
      onRequestClose={onClose}
      closeOnBackground
      elements={[
        {
          component: Modal1,
          props: {type, id},
        },
        {
          component: Modal2,
        },
      ]}
  />;
};

export default WarningTemplateModal;