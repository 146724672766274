import React, { useState } from 'react';
import './CustomerProfile.scss';
import ApplicantInfoCard from '../../components/profileComponents/ApplicantInfoCard/ApplicantInfoCard';
import ApplicationManagementCard from '../../components/profileComponents/ApplicationManagementCard/ApplicationManagementCard';
import LoanInfoCard from '../../components/profileComponents/LoanInfoCard/LoanInfoCard';
import DetailsInfoCard from '../../components/profileComponents/DetailsInfoCard/DetailsInfoCard';
import VerificationCard from '../../components/profileComponents/VerificationCard/VerificationCard';
import AffordabilityCard from '../../components/profileComponents/AffordabilityCard/AffordabilityCard';
import CreditRiskCard from '../../components/profileComponents/CreditRiskCard/CreditRiskCard';
import AccountScoreCard from '../../components/profileComponents/AccountScoreCard/AccountScoreCard';
import CallReportCard from '../../components/profileComponents/CallReportCard/CallReportCard';
import ModalManager from '../../components/ModalManager/ModalManager';
import { useOutletContext } from 'react-router-dom';

const CustomerProfile = () => {

  const [ customer, reports, agentView ] = useOutletContext() as any;

  const [ openModalId, setOpenModalId ] = useState(null);

  const handleOpenModal = (id: any) => {
    setOpenModalId(id);
  };
  const handleCloseModal = () => {
    setOpenModalId(null);
  };

  return (
      <div className="profile">
        <div className="profile__body">
          <div className="profile__left-col">
            <ApplicantInfoCard
                className="profile__card"
                prefix={customer.title}
                fullName={customer.fullName}
                dob={customer.dob}
                agreementNumber={customer.agreementNumber}
                onClick={() => handleOpenModal(6)} />
            <LoanInfoCard loanAmount={customer.loans?.actualLoan?.amount || 0}
                loanTerm={customer.loans?.actualLoan?.term  || 0}
                loanRepay={customer.loans?.actualLoan?.repayment  || 0}
                loanCost={customer.loans?.actualLoan?.cost || 0}
                loanTotal={customer.loans?.actualLoan?.total || 0}
                apr={customer.loans?.actualLoan?.apr  || 0}
                onClick={() => handleOpenModal(8)}
                className={'profile__card'} />
            <DetailsInfoCard mobilePhone={customer.mobilePhone}
                handleEmailClick={() => handleOpenModal(10)}
                handleSMSClick={() => handleOpenModal(11)}
                emailAddress={customer.emailAddress}
                currAddress={customer.addresses.current}
                prevAddress={customer.addresses.previous}
                sortCode={customer.sortCode}
                accountNumber={customer.accountNumber}
                bankName={customer.bankName}
                onClick={() => handleOpenModal(9)}
                className={'profile__card'} />
          </div>
          <div className={!agentView && 'profile__right-col' || ''}>
            <ApplicationManagementCard
                assignee={customer.assignee}
                display={agentView && 'limited' || 'combined'}
                status={customer.applicationStatus || customer.status}
                criteriaStatus={customer.criteriaStatus}
                stage={customer.applicationStage}
                onClick={() => handleOpenModal(7)}
                className={'profile__card'} />
            <VerificationCard
                report={reports.sanctions}
                display={agentView && 'limited' || 'combined'}
                onClick={() => handleOpenModal(1)}
                className={'profile__card'}
            />
            <AffordabilityCard
                report={reports.affordability}
                display={agentView && 'limited' || 'combined'}
                onClick={() => handleOpenModal(2)}
                className={'profile__card'} />
            <CreditRiskCard
                report={reports.credit}
                display={agentView && 'limited' || 'combined'}
                onClick={() => handleOpenModal(3)}
                className={'profile__card'} />
            <AccountScoreCard
                report={reports.affordability}
                display={agentView && 'limited' || 'combined'}
                onClick={() => handleOpenModal(4)}
                className={'profile__card'} />
            <CallReportCard
                report={reports.credit}
                display={agentView && 'limited' || 'combined'}
                onClick={() => handleOpenModal(5)} className={'profile__card'} />
          </div>
        </div>

        <ModalManager
            customer={customer}
            reports={reports}
            handleCloseModal={handleCloseModal}
            modalId={openModalId}
        />
      </div>
  );
};

export default CustomerProfile;
