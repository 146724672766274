import React from 'react';
import Card from '../Card/Card';
import Indicator, { IndicatorStatus } from '../Indicator/Indicator';
import './CardContainer.scss';

export interface ICardContainer {
  comment: string
  title: string
  onClick?: () => void,
  className?: string,
  status?: IndicatorStatus,
  size?: 'lg' | 'sm' | 'full'
}

const CardContainer = ({onClick, title, comment, children, status, className, size = 'lg'}: React.PropsWithChildren<ICardContainer>) => {

  return (
      <Card className={`card-container ${className}`} color="light" size={size} onClick={onClick}>
        <div className="card-container__flex">
          <span className="card-container__comment">
            {comment}
          </span>
          {status && <Indicator status={status} />}
        </div>
        <h2 className="card-container__title">
          {title}
        </h2>
        {children}
      </Card>
  );
};

export default CardContainer;
