// @ts-nocheck
import { TextStyle } from '@tiptap/extension-text-style';

export const TextStyleExtended = TextStyle.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      fontSize: {
        default: null,
        parseHTML: (element) => element.style.fontSize.replace('px', ''),
        renderHTML: (attributes) => {
          if (!attributes['fontSize']) {
            return {};
          }
          return {
            style: `font-size: ${attributes['fontSize']}px`,
            contentEditable: false,

          };
        }
      }
    };
  },

  addCommands() {
    return {
      ...this.parent?.(),
      setFontSize:
          (fontSize) =>
              ({ commands }) => {
                return commands.setMark(this.name, { fontSize: fontSize });
              },
      unsetFontSize:
          () =>
              ({ chain }) => {
                return chain()
                .setMark(this.name, { fontSize: null })
                .removeEmptyTextStyle()
                .run();
              }
    };
  }
});