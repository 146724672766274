import React, { useEffect } from 'react';
import ChainedModal from '../../ChainedModal/ChainedModal';
import DialogPopupWrapper from '../../DialogPopup/DialogPopup';
import Button from '../../Button/Button';
import '../CommsModal.scss';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { ISelectOption } from '../../CustomSelect/CustomSelect';
import { useOutletContext } from 'react-router-dom';
import { RootState } from '../../../app/store';
import { getCharsCount } from '../../../utils/getEditorCharsCount';
import { sendEmail } from '../../../app/features/comms/commsSlice';
import { EmailTemplateType, getAllTemplates } from '../../../app/features/templates/templatesSlice';
import { formatToSelect } from '../../../utils/formatToSelect';
import { html2json } from 'html2json';
import EmailContainer from '../../Comms/EmailContainer';

const Modal1 = (props: any) => {

  const dispatch = useAppDispatch();

  const [ customer ] = useOutletContext() as any;
  const {emails} = useAppSelector((state: RootState) => state.templates);
  const {user} = useAppSelector((state: RootState) => state.user);

  const {signature} = user;

  const editorRef: React.MutableRefObject<any | null> = React.useRef(null);

  const [ useSignature, setUseSignature ] = React.useState(true);
  const [ content, setContent ] = React.useState(props.content || signature);
  const [ subject, setSubject ] = React.useState(props.subject || 'subject');
  const [ templates, setTemplates ] = React.useState<ISelectOption[]>([]);
  const [ contentTouched, setContentTouched ] = React.useState(false);

  useEffect(() => {
    editorRef.current.insertContent(signature);
    if (!emails.length) {
      dispatch(getAllTemplates());
    }
  }, []);
  useEffect(() => {
    setTemplates(formatToSelect(emails));
  }, [ emails ]);

  const deleteSignature = () => {
    const totalChars = editorRef.current.getCharacterCount();
    const signatureChars = getCharsCount(signature);
    editorRef.current.deleteRange(totalChars - signatureChars, totalChars + 1);
  };
  const addSignature = () => {
    editorRef.current.insertContentAt(editorRef.current.getCharacterCount() + 1, signature);
  };
  const handleSelectedTemplate = (obj: ISelectOption) => {
    const selectedTemplate = JSON.parse(JSON.stringify(emails.find((t): boolean => t.id === obj.value) as EmailTemplateType));
    //replace variable with customer firstName
    selectedTemplate.body.content[ 0 ].content[ 0 ].text = selectedTemplate.body.content[ 0 ].content[ 0 ].text.replace(
        '${customer.fullName}', customer.fullName.split(' ')[ 0 ]);

    if (useSignature) {
      // @ts-ignore
      selectedTemplate.body.content = [ ...selectedTemplate.body.content, ...signature.content ];
    }
    editorRef.current.clearContent();
    editorRef.current.insertContent(selectedTemplate.body);
    setSubject(selectedTemplate.subject);
  };
  const handleSignature = (value: boolean) => {
    setUseSignature(value);
    value ? addSignature() : deleteSignature();
  };

  const handleCancel = () => {
    props.closeChainedModal()
  };
  const handleContentChanges = (content: string) => {
    setContent(content as any);
    setContentTouched(true);
  };

  const handleSend = () => {
    props.handleSubject(subject)
    props.handleContent(content)
    props.showNext()
  }
  return (
      <div className="comms-modal">
        <EmailContainer
            options={templates}
            subject={subject}
            setSubject={setSubject}
            setSelectedOption={handleSelectedTemplate}
            useSignature={useSignature}
            setUseSignature={handleSignature}
            handleCancel={handleCancel}
            content={content}
            setContent={handleContentChanges}
            editorRef={editorRef}
            contentTouched={contentTouched}
            title={'Email.'}
            handleSend={handleSend} />
      </div>
  );
};

const Modal2 = (props: any) => {

  const timer = React.useRef(null);

  React.useEffect(() => {
    // @ts-ignore
    timer.current = setTimeout(() => {
      props.showNext()
    }, 2500);
    return () => {
      // @ts-ignore
      clearTimeout(timer.current);
    }
  },[])

  return (
      <DialogPopupWrapper title={'Email'} className={'modal'}>
        <div className="modal__body">
          <p className="modal__text">Sending...</p>
          <Button onClick={() => props.showPrev(1)} className={'modal__btn'}>Undo</Button>
        </div>
      </DialogPopupWrapper>
  );
};
const Modal3 = (props: any) => {

  const timer = React.useRef(null);

  React.useEffect(() => {
    // @ts-ignore
    timer.current = setTimeout(() => {
      props.showNext()
    }, 1500);
    return () => {
      // @ts-ignore
      clearTimeout(timer.current);
    }
  },[])

  return (
      <DialogPopupWrapper title={'Email'} className={'modal'}>
        <div className="modal__body">
          <p className="modal__text">Message sent.</p>
          <Button onClick={() => props.showPrev(2)} className={'modal__btn'}>Undo</Button>
        </div>
      </DialogPopupWrapper>
  );
};
const Modal4 = (props: any) => {

  const dispatch = useAppDispatch();

  React.useEffect(() => {
      dispatch(sendEmail({content: html2json(props.content as any), subject: props.subject}));
  },[])

  return (
      <DialogPopupWrapper title={'Email'} className={'modal'}>
        <div className="modal__body">
          <p className="modal__text">Message sent.</p>
          <Button onClick={() => props.closeChainedModal()} className={'modal__btn'}>OK</Button>
        </div>
      </DialogPopupWrapper>
  );
};

const EmailChainModal = ({onClose, visible}: any) => {

  const [ content, setContent ] = React.useState('');
  const [ subject, setSubject ] = React.useState('');

  const handleSubject = (subject: any) => setSubject(subject)
  const handleContent = (content: any) => setContent(content)

  return <ChainedModal
      visible={visible}
      onRequestClose={onClose}
      closeOnBackground
      elements={[
        {
          component: Modal1,
          props: {
            handleSubject,
            handleContent,
            content,
            subject
          },
        },
        {
          component: Modal2,
        },
        {
          component: Modal3,
        },
        {
          component: Modal4,
          props: {
            content,
            subject
          }
        },
      ]}
  />;
};

export default EmailChainModal;
