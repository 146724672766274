import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import './LineChart.scss';
import { Chart as ChartJS, Title, Tooltip, LineElement,
  Legend, CategoryScale, LinearScale, PointElement, Filler } from 'chart.js';

ChartJS.register(Title, Tooltip, LineElement, Legend, CategoryScale, LinearScale, PointElement, Filler);

const leftStepSize = (n: number) => n / 10;
const rightStepSize = (n: number) => n / 2;

const getHighestValue = (datasets: any) => {
  const datasetsWithoutPaidValue = datasets.filter((elem: any) => elem.id !== 'paidAmount')
  const arr: any[] = []
  datasetsWithoutPaidValue.forEach((elem: any) => {
    arr.push(...elem.data)
  })
  const value = Math.max(...arr)
  return isFinite(value) ? value : 0
}
const getHighestPaidOutValue = (datasets: any) => {
  const dataset = datasets.find((elem: any) => elem.id === 'paidAmount')
  if (!dataset) {
    return 0
  }
  const value = Math.max(...dataset.data)
  return isFinite(value) ? value : 0
}

const LineChart = ({labels, datasets}: any) => {

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    plugins: {
      tooltip: {
        backgroundColor: '#fff',
        bodyColor: '#000',
        displayColors: false,
        bodySpacing: 5,
        caretSize: 0,
        padding: 10,
        caretPadding: 20,
        callbacks: {
          title: () => '',
          labelTextColor: function(context: any) {
            switch (context.dataset.label) {
              case 'Leads':
                return '#270EBF';
              case 'Applications':
                return '#1EBF1B';
              case 'Successes':
                return '#41DDFE';
              case 'Declines':
                return '#FF1513';
              case 'Paid out':
                return '#FF2DFF';
              case 'Paid out (£)':
                return '#FC9C38';
              default:
                return '#000';
            }
          },
        },
      },
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        beginAtZero: true,
        stacked: false,
        ticks: {
          maxTicksLimit: 20, //@TODO check logic
          stepSize: leftStepSize(getHighestValue(datasets)),
          display: !!getHighestValue(datasets),
        },
        grid: {
          display: false,
        },
      },
      x: {
        offset: true,
        stacked: false,

        grid: {
          display: true,
        },
        ticks: {
          autoSkip: true,
          maxRotation: 60,
          minRotation: 60,
        },
      },
      y1: {
        stacked: true,

        type: 'linear' as const,
        display: true,
        position: 'right' as const,
        beginAtZero: true,
        suggestedMax: getHighestPaidOutValue(datasets) * 2,
        grid: {
          display: true,
        },
        ticks: {
          stepSize: rightStepSize(getHighestPaidOutValue(datasets)),
          display: !!getHighestPaidOutValue(datasets),
          callback: function(value: any) {
            return '£ ' + value.toLocaleString('en-GB');
          },
        },
      },
    },
  };

  const [ data, setData ] = useState({
    labels: [],
    datasets:[],
  });

  useEffect(() => {
    setData({labels, datasets});
  }, [labels, datasets]);

  return (
      <div className="chart">
        <Line data={data} options={options} />
      </div>
  );
};

export default LineChart;
