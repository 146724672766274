import React from 'react';
import capitalizeFirstLetter from '../../../utils/capitalizeFirstLetter';
import CardContainer from '../../CardContainer/CardContainer';
import cx from 'classnames';
import { AssigneeType } from '../../../app/features/customers/customersSlice';
import './ApplicationManagementCard.scss';

interface IProps {
  status: string | null,
  stage: string | null,
  criteriaStatus: string | null,
  onClick?: () => void,
  className: string,
  display?: 'full' | 'combined' | 'limited'
  assignee: null | AssigneeType
}

const ApplicationManagementCard = ({onClick, criteriaStatus, status, stage, className, assignee, display}: IProps) => {

  const statusCapitalized = capitalizeFirstLetter(status);
  const stageCapitalized = capitalizeFirstLetter(stage);

  return (
      <CardContainer title={'Status'} comment={'application management'} className={cx(className, {
        'limited': display === 'limited',
        'management-card': true,
      })}>
        {display !== 'limited' &&
            <div className="management-card__status-wrapper">
              <h1 className="card__info">
                {statusCapitalized}
              </h1>
            </div>
        }
        <p className="management-card__status card__text">
          {stageCapitalized}
        </p>
        {display !== 'limited' &&
            <>
              <button className="card__link" onClick={onClick}>
                {assignee?.fullName ? `Assigned to ${assignee?.fullName}` : 'Awaiting assignment'}
              </button>
              <p className="management-card__status card__text">
                {criteriaStatus}
              </p>
            </>
        }
      </CardContainer>
  );
};

export default ApplicationManagementCard;
