import React from 'react';
import CardContainer from '../../components/CardContainer/CardContainer';
import { CustomerStatsType } from '../../app/features/currentStats/currentStatsSlice';
import { formatMoney } from '../../utils/formatMoney';

const DashboardCard = ({comment, title, number, customers, fullWidth}:any) => {
  return (
      <CardContainer className={'dashboard__card card'} size={'full'} comment={comment} title={title}>
        <span className="card__number">
          {number}
        </span>
        {fullWidth && customers && <ul className={'card__list'}>
          {customers.map((elem: CustomerStatsType) => (
              <li key={elem.userId} className="card__item">
                <span>{elem.fullName}</span>
                <span>{formatMoney(elem.loanAmount)}</span>
              </li>
          ))}
        </ul>}
      </CardContainer>
  );
};

export default DashboardCard;
