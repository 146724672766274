import React from 'react';
import Select, { ISelectOption } from '../../CustomSelect/CustomSelect';
import CardContainer from '../CardContainer/CardContainer';
import './ApplicantData.scss';
import Input from '../../input/Input';
import { Field, Form, Formik } from 'formik';
import cx from 'classnames';
import InputWithBtn from '../../InputWithBtn/InputWithBtn';
import * as Yup from 'yup';
import BtnGroup from '../../BtnGroup/BtnGroup';
import { sortcodeToBank } from '../../../utils/bankCheck';
import { fetchAddressesAPI } from '../../../app/features/customers/customersAPI';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { showToast } from '../../../app/features/ui/uiSlice';
import { getChangedValues } from '../../../utils/getChangedValues';
import { setSuccess, updateInfo } from '../../../app/features/customers/customersSlice';
import { formatToNormalText } from '../../../utils/formatToNormalText';

type AddressResponseType = {
  [ key: string ]: string
}

const formatAddressForSelect = (arr: AddressResponseType[]): ISelectOption[] => {
  return arr.map((elem, idx) => {
    return {
      value: idx,
      label: elem.addressFull,
    };
  });
};

interface IApplicantData {
  mobilePhone: string;
  emailAddress: string;
  postCode: string;
  sortCode: string | null;
  accountNumber: string | null;
  prevPostCode: string | null;
  residentialStatus: string | null;
}

const ApplicantData = ({mobilePhone, emailAddress, postCode, sortCode, accountNumber, residentialStatus, prevPostCode}: IApplicantData) => {

      const ValidationSchema = Yup.object().shape({
        mobilePhone: Yup.string().transform(value => value.replace(/\s/g, '')).min(11).max(11).required('required'),
        emailAddress: Yup.string().email('Invalid email').required('required'),
        accountNumber: Yup.string().min(8).max(8).required('required'),
        sortCode: Yup.string().transform(value => value.replace(/[-]/g, '')).min(6).max(6).required('required'),
        postCode: Yup.string().min(3).max(11).required('required'),
        prevPostCode: Yup.string().min(3).max(11).nullable(),
      });

      const dispatch = useAppDispatch();

      const {isModalLoading, isSuccess} = useAppSelector((state) => state.customers);

      React.useEffect(() => {
        if (isSuccess) {
          setTimeout(() => {
            dispatch(setSuccess(false));
          }, 5000);
        }
      }, [ isSuccess ]);

      const [ currAddresses, setCurrAddresses ] = React.useState<AddressResponseType[] | []>([]);
      const [ prevAddresses, setPrevAddresses ] = React.useState<AddressResponseType[] | []>([]);
      const [ currAddressIdx, selectCurrAddressIdx ] = React.useState<number | null>(null);
      const [ prevAddressIdx, selectPrevAddressIdx ] = React.useState<number | null>(null);

      const fetchAddresses = async(postCode: string, prev = false) => {
        try {
          const {data} = await fetchAddressesAPI(postCode);
          if (!data.length) {
            dispatch(showToast({message: 'No address found for that postcode.', severity: 'failed'}));
          }
          prev ? setPrevAddresses(data) : setCurrAddresses(data);
        } catch (e: any) {
          dispatch(showToast({message: e.response.data.message, severity: 'failed'}));
        }
      };

      const handleCurrAddress = async(pc: string) => {
        if (!pc) return;
        await fetchAddresses(pc);
      };

      const handlePrevAddress = async(pc: string) => {
        if (!pc) return;
        //@TODO add curr - prev postcodes check
        await fetchAddresses(pc, true);
      };

      const handleCancelClick = () => {
        setCurrAddresses([]);
        setPrevAddresses([]);
        selectCurrAddressIdx(null);
        selectPrevAddressIdx(null);
      };

      const formattedCurrAddress = formatAddressForSelect(currAddresses);
      const formattedPrevAddress = formatAddressForSelect(prevAddresses);

      const combineAddresses = () => {
        if (currAddressIdx === null && prevAddressIdx === null) {
          return null;
        }
        const addresses = {};
        if (currAddressIdx) {
          (addresses as any).current = currAddresses[ currAddressIdx ];
        }
        if (prevAddressIdx) {
          (addresses as any).previous = prevAddresses[ prevAddressIdx ];
        }
        return {addresses};
      };

      return (
          <CardContainer className="applicant-data" size={'lg'} comment={'Application data'}>
            <div className="applicant-data__container">
              <Formik
                  initialValues={{
                    mobilePhone,
                    emailAddress,
                    postCode,
                    prevPostCode,
                    sortCode,
                    accountNumber,
                    residentialStatus: residentialStatus && formatToNormalText(residentialStatus)
                  }}
                  validationSchema={ValidationSchema}
                  onSubmit={() => {}}>
                {({
                  errors,
                  handleChange,
                  values,
                  touched,
                  resetForm,
                  initialValues,
                  dirty, isSubmitting,
                  isValid,
                }) => {
                  return (
                      <Form className="applicant-data__form form" autoComplete="off">
                        <Field
                            id="mobilePhone"
                            name="mobilePhone"
                            mask={'99999 999 999'}
                            maskChar=""
                            alwaysShowMask={true}
                            onChange={handleChange('mobilePhone')}
                            error={touched.mobilePhone && errors.mobilePhone}
                            className={cx({
                              [ 'form__field' ]: true,
                              error: touched.mobilePhone && errors.mobilePhone,
                            })}
                            as={Input} />
                        <Field
                            id="emailAddress"
                            name="emailAddress"
                            onChange={handleChange('emailAddress')}
                            error={touched.emailAddress && errors.emailAddress}
                            className={cx({
                              [ 'form__field' ]: true,
                              error: touched.emailAddress && errors.emailAddress,
                            })}
                            as={Input} />
                        {currAddresses.length
                            ? <Field
                                id="postCode"
                                name="postCode"
                                onChange={(obj: ISelectOption) => selectCurrAddressIdx(obj.value as number)}
                                selectedOption={formattedCurrAddress.filter((o) => o.value === currAddressIdx)}
                                optionsList={formattedCurrAddress}
                                className={cx({
                                  [ 'form__field' ]: true,
                                })}
                                as={Select} />
                            : <Field
                                id="postCode"
                                name="postCode"
                                onChange={handleChange('postCode')}
                                error={touched.postCode && errors.postCode}
                                onClick={() => handleCurrAddress(values.postCode)}
                                children={'Search'}
                                className={cx({
                                  [ 'form__field' ]: true,
                                })}
                                as={InputWithBtn} />}
                        {prevAddresses.length
                            ? <Field
                                id="prevPostCode"
                                name="prevPostCode"
                                onChange={(obj: ISelectOption) => selectPrevAddressIdx(obj.value as number)}
                                selectedOption={formattedPrevAddress.filter((o) => o.value === prevAddressIdx)}
                                optionsList={formattedPrevAddress}
                                className={cx({
                                  [ 'form__field' ]: true,
                                })}
                                as={Select} />
                            : <Field
                                id="prevPostCode"
                                name="prevPostCode"
                                onChange={handleChange('prevPostCode')}
                                error={touched.prevPostCode && errors.prevPostCode}
                                onClick={() => handlePrevAddress(values.prevPostCode!)}
                                children={'Search'}
                                className={cx({
                                  [ 'form__field' ]: true,
                                })}
                                as={InputWithBtn} />}
                        <Field
                            id="residentialStatus"
                            name="residentialStatus"
                            disabled={true}
                            className={cx({
                              [ 'form__field' ]: true,
                            })}
                            as={Input} />
                        <Field
                            id="sortCode"
                            name="sortCode"
                            onChange={handleChange('sortCode')}
                            error={touched.sortCode && errors.sortCode}
                            mask={'99-99-99'}
                            maskChar=""
                            alwaysShowMask={true}
                            className={cx({
                              [ 'form__field' ]: true,
                              error: touched.sortCode && errors.sortCode,
                            })}
                            as={Input} />
                        <Field
                            id="accountNumber"
                            name="accountNumber"
                            mask={'99999999'}
                            maskChar=""
                            alwaysShowMask={true}
                            onChange={handleChange('accountNumber')}
                            error={touched.accountNumber && errors.accountNumber}
                            className={cx({
                              [ 'form__field' ]: true,
                              error: touched.accountNumber && errors.accountNumber,
                            })}
                            as={Input} />
                        <div className="applicant-data__details details">
                          <p className="details__item">
                            {sortcodeToBank(values.sortCode)}
                          </p>
                        </div>
                        <BtnGroup
                            className={'applicant-data__btn-block'}
                            isActive={dirty || isSubmitting}
                            onClick={() => {
                              if (isValid) {
                                let updatedFields = getChangedValues(values, initialValues);
                                if ((updatedFields as any).prevPostCode) {
                                  delete (updatedFields as any).prevPostCode;
                                }
                                if ((updatedFields as any).postCode) {
                                  delete (updatedFields as any).postCode;
                                }
                                if (updatedFields.hasOwnProperty('sortCode')) {
                                  (updatedFields as any).sortCode = (updatedFields as any).sortCode.replace(/-/g, '');
                                  const bankName = sortcodeToBank(values.sortCode);
                                  dispatch(updateInfo({...updatedFields, bankName, ...combineAddresses()}));
                                } else {
                                  dispatch(updateInfo({...updatedFields, ...combineAddresses()}));
                                }
                              }
                            }}
                            onCancel={() => {
                              handleCancelClick();
                              resetForm();
                            }}
                            isLoading={isModalLoading}
                            isSuccess={isSuccess}
                        />
                      </Form>
                  );
                }}
              </Formik>
            </div>
          </CardContainer>
      );
    }
;

export default ApplicantData;