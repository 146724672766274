import React from 'react';
import Select, { SingleValue } from 'react-select';
import './CustomSelect.scss'

export interface ISelectOption {
  value: string | number;
  label: string;
}

interface ISelect {
  optionsList: ISelectOption[];
  onChange?: (newValue: SingleValue<ISelectOption>) => void;
  selectedOption: ISelectOption | undefined,
  className?: string,
  label?: string,
  placeholder?: string
}

const CustomSelect = ({optionsList, className, selectedOption, onChange, placeholder, label}: ISelect) => {

  const customStyles = {
    control: () => ({
      padding: 0,
      margin: 0,
      height: '30px',
      fontSize: '12px',
      display: 'flex',
      background: '#ffff',
      border: 'none',
      borderRadius: 0,
      borderTopLeftRadius: '7.5px',
      color: '#313132',
      borderTopRightRadius: '7.5px',
      borderBottom: '1px solid #CFCFD1',
      fontFamily: 'Poppins',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      color: '#313132',
    }),
    placeholder: (base: any) => ({
      ...base,
      color: '#313132',
    }),
    option: (base: any, {isFocused, isSelected}: any) => ({
      fontSize: '12px',
      background: isFocused
          ? '#6E6E73'
          : isSelected
              ? '#6E6E73'
              : undefined,
      margin: '5px 5px',
      padding: '5px 10px',
      borderRadius: '15px',
      color: isFocused
          ? '#ffffff'
          : isSelected
              ? '#ffffff'
              : undefined,
    }),
    menu: () => ({
      backgroundColor: '#ffffff',
      borderBottomLeftRadius: '7.5px',
      borderBottomRightRadius: '7.5px',
      boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.1)',
    }),
    menuPortal: ({ left, top, ...provided }: any, state: any) => ({
      position: 'absolute',
      ...provided
    })
  };

  return (
      <div className={`select ${className}`}>
        {label && <label className={'select__label'}>
          {label}
        </label>}
        <Select
            menuPosition='fixed'
            styles={customStyles}
            value={selectedOption}
            onChange={onChange}
            options={optionsList}
            placeholder={placeholder}
        />
      </div>
  );
};

export default CustomSelect;