import CustomAxios from '../../CustomAxios/CustomAxios';

export const declineCustomerAPI = (id: string, data: { password: string, reason: string }) => {
  return CustomAxios.post(`${process.env.REACT_APP_CUSTOMERS_URL}/dev/customer/${id}/decline`, data);
};
export const payoutCustomerAPI = (id: string, data: { password: string }) => {
  return CustomAxios.post(`${process.env.REACT_APP_CUSTOMERS_URL}/dev/customer/${id}/payout`, data);
};
export const notTakenUpAPI = (id: string, data: { password: string }) => {
  return CustomAxios.post(`${process.env.REACT_APP_CUSTOMERS_URL}/dev/customer/${id}/ntu`, data);
};
export const doNotLendCustomerAPI = (id: string, data: { password: string }) => {
  return CustomAxios.post(`${process.env.REACT_APP_CUSTOMERS_URL}/dev/customer/${id}/dnl`, data);
};
export const refreshAffordabilityCustomerAPI = (id: string) => {
  return CustomAxios.get(`${process.env.REACT_APP_CUSTOMERS_URL}/dev/customer/${id}/refresh-affordability`);
};
export const getSanctionsAPI = (id: string) => {
  return CustomAxios.get(`${process.env.REACT_APP_CUSTOMERS_URL}/dev/customer/${id}/sanctions`);
};
export const getCreditAPI = (id: string) => {
  return CustomAxios.get(`${process.env.REACT_APP_CUSTOMERS_URL}/dev/customer/${id}/credit`);
};