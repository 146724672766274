import React from 'react';
import './style.scss';

const Chevron = ({className}: any) => {
  return (
      <div className={className}>
        <ol className="steps">
          <li><span>Landing Page</span></li>
          <li><span>Open Banking</span></li>
          <li><span>Affordability</span></li>
          <li><span>Budget</span></li>
          <li><span>Agreement</span></li>
        </ol>
      </div>
  );
};

export default Chevron;
