import React from 'react';
import CardContainer from '../CardContainer/CardContainer';
import Button from '../../Button/Button';
import './AssignModal.scss';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import CustomSelect from '../../CustomSelect/CustomSelect';
import { getUsers } from '../../../app/features/users/usersSlice';
import { assignCustomer, AssigneeType } from '../../../app/features/customers/customersSlice';

interface IAssignModal {
  onClose: () => void;
  assignedUser: AssigneeType | null;
}

const AssignModal = ({onClose, assignedUser}: IAssignModal) => {

  const dispatch = useAppDispatch();

  const {users} = useAppSelector((state) => state.users);

  const [ usersList, setUsers ] = React.useState<any>([]);

  const [ selectedUser, setUser ] = React.useState<any>({});

  React.useEffect(() => {
    dispatch(getUsers());
  }, []);

  React.useEffect(() => {
    const newUsersList = users.map(item => {
      return {
        value: item.userId,
        label: item.fullName,
      };
    });

    if (assignedUser) {
      const user = newUsersList.find(u => u.value === assignedUser.userId);
      setUser(user);
    }

    setUsers(newUsersList);
  }, [ users, assignedUser ]);

  const onClick = () => {
    dispatch(assignCustomer(selectedUser.value));
    onClose();
  };

  const handleSetUser = (obj: any) => {
    setUser(obj);
  };

  return (
      <CardContainer className={'assign-modal'} size={'lg'} comment={'Application management'}>
        <CustomSelect label={'Assign to'}
            className={'assign-modal__select'}
            optionsList={usersList}
            placeholder={'assign to'}
            selectedOption={selectedUser}
            onChange={handleSetUser}
        />
        <Button onClick={onClick} className={'assign-modal__btn'}>ok</Button>
      </CardContainer>
  );
};

export default AssignModal;
