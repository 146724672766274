import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';

const getToken = () => JSON.parse(localStorage.getItem('token') as any);

let CustomAxios = axios.create({withCredentials: true})

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {

  if (config.headers === undefined) {
    config.headers = {};
  }

  config.headers[ 'Authorization' ] = `Bearer ${getToken()}`;
  // console.info(`[request] [${JSON.stringify(config)}]`);
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  console.error(`[request error] [${JSON.stringify(error)}]`);
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  // console.info(`[response] [${JSON.stringify(response)}]`);
  return response;
};

const onResponseError = async (error: AxiosError): Promise<AxiosError | AxiosRequestConfig> => {
  console.error(`[response error] [${JSON.stringify(error)}]`);
  const originalReq: any = error.config
  const status = error.response ? error.response.status : null;

  if (status === 401) {
    try {
      const res = await axios.get(`${process.env.REACT_APP_AUTH_URL}/dev/auth/refresh`, {
        withCredentials: true,
      });
      localStorage.setItem('token', JSON.stringify(res.data));
      return CustomAxios(originalReq);
    } catch (e) {
      localStorage.clear()
      window.location.href = "/login";
      throw e
    }
  }
  return Promise.reject(error);
};

const setupInterceptorsTo = (axiosInstance: AxiosInstance): AxiosInstance => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};

CustomAxios = setupInterceptorsTo(CustomAxios);

export default CustomAxios;