import React from 'react';
import CardContainer from '../CardContainer/CardContainer';
import Input from '../../input/Input';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import cx from 'classnames';
import './ApplicantCardModal.scss';
import CustomSelect, { ISelectOption } from '../../CustomSelect/CustomSelect';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setSuccess, updateInfo } from '../../../app/features/customers/customersSlice';
import BtnGroup from '../../BtnGroup/BtnGroup';
import { getChangedValues } from '../../../utils/getChangedValues';
import { handleDob } from '../../../helpers/handleDob';

interface IApplicantCardModal {
  title: string;
  firstName: string;
  lastName: string;
  dob: string;
}

const ApplicantCardModal = ({title, firstName, lastName, dob}: IApplicantCardModal) => {

  const dispatch = useAppDispatch();

  const {isModalLoading, isSuccess} = useAppSelector((state) => state.customers);

  React.useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        dispatch(setSuccess(false));
      }, 5000);
    }
  }, [ isSuccess ]);

  const titleOptions: ISelectOption[] = [
    {label: 'Mr', value: 'Mr'},
    {label: 'Ms', value: 'Ms'},
    {label: 'Mrs', value: 'Mrs'},
    {label: 'Miss', value: 'Miss'},
  ];

  const ApplicationCardSchema = Yup.object().shape({
    firstName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
    lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
    dob: Yup.string().required('Required').
        test('dob', 'Applicant must be between 18 and 28 years old', (value) => handleDob(value, new Date())),
  });

  return (
      <CardContainer className={'applicant-card'} size={'lg'} comment={'Information about the applicant'}>
        <div className="applicant-card__body">
          <Formik
              initialValues={{
                firstName,
                lastName,
                dob,
                title,
              }}
              validationSchema={ApplicationCardSchema}
              onSubmit={() => {}}>
            {({
              errors,
              handleChange,
              values,
              dirty,
              isSubmitting,
              resetForm,
              setFieldValue,
              initialValues,
            }) => (
                <Form className={'applicant-card__form form'} autoComplete="off">
                  <div className="form__wrapper">
                    <Field
                        id="title"
                        name="title"
                        optionsList={titleOptions}
                        selectedOption={titleOptions.filter((o) => o.value === values.title)}
                        onChange={(option: ISelectOption) => { setFieldValue('title', option.value); }}
                        as={CustomSelect} />
                    <Field
                        id="firstName"
                        name="firstName"
                        onChange={handleChange('firstName')}
                        error={errors.firstName}
                        className={cx({
                          'form__input': true,
                          error: errors.firstName,
                        })}
                        as={Input} />
                    <Field
                        id="lastName"
                        name="lastName"
                        onChange={handleChange('lastName')}
                        error={errors.lastName}
                        className={cx({
                          'form__input': true,
                          error: errors.lastName,
                        })}
                        as={Input} />
                    <Field
                        id="dob"
                        name="dob"
                        mask={'99/99/9999'}
                        maskChar=""
                        alwaysShowMask={true}
                        onChange={handleChange('dob')}
                        error={errors.dob}
                        className={cx({
                          'form__input': true,
                          error: errors.dob,
                        })}
                        as={Input} />
                  </div>
                  <div className="applicant-card__btn-block">
                    <BtnGroup
                        className={'applicant-data__btn-block'}
                        isActive={dirty || isSubmitting}
                        onClick={() => {
                          let updatedFields = getChangedValues(values, initialValues);
                          dispatch(updateInfo(updatedFields));
                        }}
                        onCancel={() => {
                          resetForm();
                        }}
                        isLoading={isModalLoading}
                        isSuccess={isSuccess}
                    />
                  </div>
                </Form>
            )}
          </Formik>
        </div>
      </CardContainer>
  );
};

export default ApplicantCardModal;
