export const formatMoney = (amount: number | string) => {
  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  });

  if (!amount) return formatter.format(0);

  if (typeof amount === 'number') {
    return formatter.format(amount);
  }
  // @ts-ignore
  return formatter.format(amount.replace(/[.,\s]/g, '').replace(/\d(?=(\d{3})+\.)/g, '$&,'));
};