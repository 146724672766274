import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AuthData } from '../../../pages/LoginPage/LoginPage';
import { signInAPI, getMeAPI, signOutAPI } from './authAPI';
import { setUser } from '../user/userSlice';

type SliceState = {
  isLoading: boolean,
  isAuth: boolean,
  error: null | string,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLoading: false,
    isAuth: false,
    error: null,
  } as SliceState,
  reducers: {},
  extraReducers: (b) => {
    b.addCase(getMe.pending, (state) => {
      state.isLoading = true;
    });
    b.addCase(getMe.fulfilled, (state, {payload}) => {
      state.isLoading = false;
      state.isAuth = true;
    });
    b.addCase(getMe.rejected, (state) => {
      state.isAuth = false;
      state.isLoading = false;
    });

    b.addCase(signIn.pending, (state) => {
      state.isLoading = true;
    });
    b.addCase(signIn.fulfilled, (state) => {
      state.isLoading = false;
      state.isAuth = true;
    });
    b.addCase(signIn.rejected, (state, action: any) => {
      state.error = action.payload;
      state.isLoading = false;
    });
    b.addCase(singOut.pending, (state) => {
      state.isLoading = true;
    });
    b.addCase(singOut.fulfilled, (state) => {
      state.isLoading = false;
      state.isAuth = false;
    });
    b.addCase(singOut.rejected, (state, action: any) => {
      state.error = action.payload;
      state.isLoading = false;
    });
  },
});

export const signIn = createAsyncThunk('auth/signIn', async(data: AuthData, {rejectWithValue, dispatch}) => {
  try {
    const response = await signInAPI(data);
    if (response.data.token) {
      localStorage.setItem('token', JSON.stringify(response.data.token));
    }
    dispatch(setUser(response.data.user));
    return response.data;
  } catch (err: any) {
    return rejectWithValue(err?.response?.data?.message || 'Internal server error');
  }
});

export const singOut = createAsyncThunk('auth/signOut', async(data, {rejectWithValue, dispatch}) => {
  try {
    const response = await signOutAPI();
    // localStorage.clear()
    window.location.href = "/login";
    return response.data;
  } catch (err: any) {
    return rejectWithValue(err?.response?.data?.message || 'Internal server error');
  }
});

export const getMe = createAsyncThunk('auth/getMe', async(data, {rejectWithValue, dispatch}) => {
  try {
    const response = await getMeAPI();
    dispatch(setUser(response.data));
    return response.data;
  } catch (err: any) {
    return rejectWithValue(err?.response?.data?.message || 'Internal server error');
  }
});

export default authSlice.reducer;