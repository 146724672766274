import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { updateUserAPI } from './userAPI';
import { showToast } from '../ui/uiSlice';

type SliceState = {
  isLoading: boolean,
  error: null | string,
  isSuccess: boolean
  user: {
    id: string,
    fullName: string,
    email: string,
    signature: {},
  },
}

const initialState: SliceState = {
  isLoading: false,
  error: null,
  isSuccess: false,
  user: {
    id: '',
    fullName: '',
    email: '',
    signature: {},
  },
};

const slice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setUser: (state, {payload}) => {
      state.user = {...state.user, ...payload};
    },
    setSuccess: (state, {payload}) => {
      state.isSuccess = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateUser.pending, (state) => {
      state.isSuccess = false;
      state.isLoading = true;
    });
    builder.addCase(updateUser.fulfilled, (state, {payload}) => {
      state.user = {...state.user, ...payload}
      state.isSuccess = true;
      state.isLoading = false;
    });
    builder.addCase(updateUser.rejected, (state, {payload}: any) => {
      state.error = payload.error;
      state.isLoading = false;
    });
  },
});

export const updateUser = createAsyncThunk('user/updateUser', async(data: any, {rejectWithValue, dispatch}) => {
  try {
    const response = await updateUserAPI(data);
    return response.data;
  } catch (err: any) {
    if (err.response.status !== 500) {
      dispatch(showToast({message: err.response.data.message, severity: 'failed'}));
    } else {
      dispatch(showToast({message: 'Something went wrong :(', severity: 'failed'}));
    }
    return rejectWithValue({
      error: err.response.data.message,
    });
  }
});

export const {setUser, setSuccess} = slice.actions;

export const userReducer = slice.reducer;