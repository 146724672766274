import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS, Title, Tooltip, LineElement,
  Legend, CategoryScale, LinearScale, PointElement, Filler,
} from 'chart.js';

ChartJS.register(Title, Tooltip, LineElement, Legend, CategoryScale, LinearScale, PointElement, Filler);

interface LineChartProps {
  data: any;
  options?: any;
}

const LineChart: React.FC<LineChartProps> = ({data, options}) => {
  return (
        <Line data={data} options={options} />
  );
};

export default LineChart;