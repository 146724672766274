type ValuesType = {
  [key: string]: string | number | null
}

export const getChangedValues = (values: ValuesType, initialValues: ValuesType) => {
    return Object.entries(values).reduce((acc: {}, [ key, value ]) => {
    const hasChanged = initialValues[ key ] !== value;
    if (hasChanged) {
      // @ts-ignore
      acc[ key ] = value;
    }
    return acc;
  }, {});
};