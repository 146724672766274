export const names: string[] = [
  'Broker landing page',
  'Open banking introduction',
  'AccountScore handoff',
  'Connect another account',
  'Loan purpose',
  'Income change',
  'Affordability questions',
  'Alternative offer',
  'Budget plan',
  'Agreement',
  'Direct Debit mandate',
];