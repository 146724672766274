import { Editor } from '@tiptap/core';
import StarterKit from '@tiptap/starter-kit';
import TextStyle from '@tiptap/extension-text-style';
import { TextStyleExtended } from '../components/TextEditor/extension/FontSize';

export function getCharsCount(content: any) {
  const editor = new Editor({
    extensions: [ StarterKit, TextStyle, TextStyleExtended ],
    content,
  });
  return editor.getCharacterCount();
}
