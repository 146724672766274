import React from 'react';
import './CustomersTable.scss';
import CustomLink from '../CustomLink/CustomLink';
import Loader from '../Loader/Loader';
import { format } from 'date-fns';
import { formatMoney } from '../../utils/formatMoney';
import { ReportType } from '../../app/features/reports/reportsSlice';
import { formatStatus } from '../../helpers/formatStatus';

interface ICustomersTable {
  customers: ReportType[];
  isLoading?: boolean;
}

const CustomersTable = ({customers, isLoading}: ICustomersTable) => {

  return (
      <table className={'customers__table'}>
        <thead>
        <tr>
          <th>Name</th>
          <th>DOB</th>
          <th>Address</th>
          <th>Post code</th>
          <th>Lead date</th>
          <th>Agreement</th>
          <th>Loan amount</th>
          <th>Status</th>
        </tr>
        </thead>
        <tbody>
        {isLoading && <Loader />}
        {!!customers.length && customers.map((elem: ReportType) => {
          return (
              <tr>
                <td><CustomLink to={`/customers/${elem.userId}`}>{elem.fullName}</CustomLink></td>
                <td>{elem.dob}</td>
                <td>{elem.address}</td>
                <td>{elem.postCode}</td>
                <td>{format(new Date(elem.createdAt), 'dd/MM/y')}</td>
                <td>{elem.agreementNumber}</td>
                <td>{formatMoney(elem.loanAmount)}</td>
                <td>{formatStatus(elem.status)}</td>
              </tr>
          );
        })}
        </tbody>
      </table>
  );
};

export default CustomersTable;
