import React, { useEffect, useState } from 'react';
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import Input from '../../components/input/Input';
import Button from '../../components/Button/Button';
import './TemplatesPage.scss';
import { RootState, useAppDispatch } from '../../app/store';
import { useAppSelector } from '../../app/hooks';
import { addTemplate, getAllTemplates, setSuccess, updateTemplate } from '../../app/features/templates/templatesSlice';
import Editor from '../../components/TextEditor/TextEditor';
import { Field, Form, FormikProvider, useFormik } from 'formik';
import BtnGroup from '../../components/BtnGroup/BtnGroup';
import Title from '../../components/Title/Title';
import { formatToSelect } from '../../utils/formatToSelect';
import WarningTemplateModal from '../../components/DialogModals/WarningTemplateModal/WarningTemplateModal';

const TemplatesPage = ({ className }: any) => {

  const dispatch = useAppDispatch();
  const { isLoading, isSuccess, isDeleteSuccess, emails, sms } = useAppSelector(
    (state: RootState) => state.templates);

  const [activeTemplates, setActiveTemplates] = useState<any[]>([]);

  useEffect(() => {
    if (!emails.length || !sms.length) {
      dispatch(getAllTemplates());
    }
  }, []);

  const mediaOptions = [
    { label: 'SMS', value: 'sms' },
    { label: 'Email', value: 'email' }
  ];

  const [selectedMediaOption, setSelectedMediaOption] = React.useState('');
  const [selectedOption, setSelectedOption] = React.useState('');
  const [content, setContent] = React.useState('');
  const [contentTouched, setContentTouched] = React.useState(false);

  const [editView, setEditView] = React.useState(true);
  const [showModal, setShowModal] = React.useState(false);

  const handleCancel = () => {
    setSelectedOption('');
    setSelectedMediaOption('');
    setContent('');
    editorRef.current.clearContent();
  };

  const handleSend = () => {
    // setSelectedOption('');
  };

  const handleUpdate = (e: any) => {
    e.preventDefault();
    const content = selectedMediaOption === 'sms' ? editorRef.current.getText() : editorRef.current.getJSON();
    dispatch(updateTemplate({ type: selectedMediaOption, id: selectedOption, content: content }))
  };

  const handleActiveTemplate = (template: string) => {
    setSelectedMediaOption(template);
    setActiveTemplates(formatToSelect(template === 'sms' ? sms : emails));
    editorRef.current.clearContent();
  };

  const selectPartFromTemplate = (id: string) => {
    const templates = { emails, sms };
    const type = selectedMediaOption === 'email' ? 'emails' : 'sms';
    // @ts-ignore
    const selectedTemplate = templates[type].find((elem: any) => elem.id === id);
    return {
      content: selectedMediaOption === 'sms' ? selectedTemplate.text : selectedTemplate.body,
      name: selectedTemplate.name
    };
  };

  const editorRef: React.MutableRefObject<any | null> = React.useRef(null);

  const handleSelectedTemplate = (id: string) => {
    setSelectedOption(id);
    const template = selectPartFromTemplate(id);
    editorRef.current.clearContent();
    editorRef.current.insertContent(template.content);
  };

  const handleContentChanges = (content: string) => {
    setContent(content);
    setContentTouched(true);
  };

  React.useEffect(() => {
    if (isDeleteSuccess) {
      editorRef.current.clearContent();
    }
  }, [isDeleteSuccess]);

  React.useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        dispatch(setSuccess(false));
      }, 5000);
    }
  }, [isSuccess]);

  const handleViewChange = () => {
    handleCancel();
    setEditView(!editView);
  };

  const formik = useFormik({
    initialValues: {
      name: ''
    },
    // validationSchema: {schema},
    onSubmit: () => {
    }
  });

  return (
    <div className={`templates ${className}`}>
      <Title text={'Templates.'} />
      <div className={`templates__body`}>
        <div className="templates__body_top">
        </div>
        <div className="templates__subtitle-block">
          <h2 className="templates__subtitle">
            {editView ? 'Edit template' : 'Create template'}
          </h2>
          <Button onClick={handleViewChange} className="templates__btn">{editView ? 'New' : 'Edit'}</Button>
        </div>
        <FormikProvider value={formik}>
          <Form className="applicant-data__form form" autoComplete="off">
            <Field
              label={'Media'}
              optionsList={mediaOptions}
              onChange={(e: any) => handleActiveTemplate(e.value)}
              selectedOption={mediaOptions.filter((o: any) => o.value === selectedMediaOption)}
              className={`templates__field`}
              placeholder={'Email or SMS?'}
              as={CustomSelect} />
            {editView
              ? <Field
                label={'Template'}
                optionsList={activeTemplates}
                onChange={(e: any) => handleSelectedTemplate(e.value)}
                selectedOption={activeTemplates.filter((o: any) => o.value === selectedOption)}
                className={`templates__field`}
                placeholder={'Pick a template to edit'}
                as={CustomSelect} />
              : <Field
                id="name"
                name="name"
                placeholder={'Name your template'}
                onChange={formik.handleChange('name')}
                error={formik.touched.name && formik.errors.name}
                className={`templates__field`}
                label={'Template name'}
                as={Input} />}
            <Editor content={content}
                    className={'templates__editor'}
                    label={'Content'}
                    ref={editorRef}
                    onChange={handleContentChanges} />
            {editView
              ? <div className={`templates__btn-block`}>
                <Button disabled={!contentTouched && !selectedMediaOption}
                        onClick={handleCancel}
                        className={`templates__btn`}>Cancel</Button>
                <Button disabled={!selectedOption}
                        onClick={() => setShowModal(true)}
                        className={`templates__btn`}>Delete</Button>
                <Button disabled={!contentTouched && !selectedMediaOption}
                        className={`templates__btn`}
                        onClick={(e: any) => handleUpdate(e)}>Save</Button>
              </div>
              :
              <div className={`templates__btn-block`}>
                <BtnGroup
                  className={'applicant-data__btn-block'}
                  isActive={formik.dirty || formik.isSubmitting}
                  onCancel={() => {
                    formik.resetForm();
                  }}
                  onClick={() => {
                    const content = selectedMediaOption === 'sms' ? editorRef.current.getText() : editorRef.current.getJSON();
                    dispatch(addTemplate({
                      type: selectedMediaOption,
                      data: {
                        name: formik.values.name,
                        content,
                        subject: formik.values.name
                      }
                    }));
                  }}
                  isLoading={isLoading}
                  isSuccess={isSuccess}
                />
              </div>}
          </Form>
        </FormikProvider>
      </div>
      <WarningTemplateModal
        visible={showModal}
        type={selectedMediaOption}
        id={selectedOption}
        onClose={() => setShowModal(false)}
      />
    </div>
  );
};

export default TemplatesPage;